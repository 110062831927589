import { DATA_CONFIGURATION_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import SidStarPage from "./SidStarPage/SidStarPage";

const dataConfigRouterConfig: routeConfig[] = [
  {
    path: DATA_CONFIGURATION_PATHS.cxSidStar,
    component: SidStarPage,
  },
  {
    path: DATA_CONFIGURATION_PATHS.uoSidStar,
    component: SidStarPage,
  },
];

export default dataConfigRouterConfig;
