import * as React from 'react';
import moment from 'moment';
import { CTableRow, CTableDataCell, CCol, CRow } from '@coreui/react';

import { DeleteIcon, EditIcon, RightArrowLgIcon } from '../../assets/images';

import { ISNNRule } from '../../slice/simSlice';


interface ISNNRulesTableItemProps {
    snnRule: ISNNRule;
    dispatchReducer: React.Dispatch<any>;
    readOnly: boolean;
}

const SNNRulesTableItem = ({ snnRule, dispatchReducer, readOnly }: ISNNRulesTableItemProps) => {

  const EditButton = () => {
    return <div className={`${snnRule.isActive ? "snnTableRow clickable": "clickable"}`} style={{ marginLeft: "4px" }}>
      <EditIcon height={40} width={40} onClick={() => {
        dispatchReducer({ type: "EDIT", snnRule: snnRule });
      }} />
    </div>
  };

  const RobotoMonoRgDataCell = ({ children, type }) => {
    return <CTableDataCell className={`RobotoMonoRg tableItem px-1 ${type}`} style={{ position: "relative" }} align='middle'>{ children }</CTableDataCell>
  };


  return <CTableRow className={`${snnRule.isActive ? "snnTableRow": ""}`} style={{ borderBottom: "1px solid #D8D8D8" }}>
    <RobotoMonoRgDataCell type="airline">
      <div>
        {snnRule.airline || "--"}
      </div>
      <div>
        {snnRule.flightNo || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="aircraftType">{snnRule.aircraftType || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="registration">{snnRule.registration || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="dep">
      {Array.isArray(snnRule.dep) && snnRule.dep.length > 0 ? snnRule.dep.map((ori, index) =>
        <div key={`ori_${index}`}>{ori}</div>
      )
      :
      <div>{"--"}</div>
      }
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="arr">
      {Array.isArray(snnRule.arr) && snnRule.arr.length > 0 ? snnRule.arr.map((arr, index) =>
        <div key={`arr${index}`}>{arr}</div>
      )
      :
      <div>{"--"}</div>
      }
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="altn">
      <div>
        {snnRule.era || "--"}
      </div>
      <div>
        {snnRule.altn || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="startAt">
      <div>
        {moment.utc(snnRule.startAt)?.format("DDMMMYYYY HH:mm") || "--"}
      </div>
      <div>
        {moment.utc(snnRule.endAt)?.format("DDMMMYYYY HH:mm") || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="timeRange">
      <div>
        {snnRule.timeRange || "--"}
      </div>
      <div>
        {snnRule.isInterval && snnRule.isInterval === true ? "Y" : "N" || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="waypoint">
      <div>
        {snnRule.waypoint || "--"}
      </div>
      <div>
        {snnRule.countryICAOCode || "--"}
      </div>
    </RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="createBy">{snnRule.createBy || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="version">{snnRule.version || "--"}</RobotoMonoRgDataCell>
    <RobotoMonoRgDataCell type="snn">{snnRule.snn || "--"}</RobotoMonoRgDataCell>
    {!readOnly &&
      <RobotoMonoRgDataCell type="action">
        <CRow>
          <CCol sm="auto" ><EditButton/></CCol>
          <CCol sm="auto"><DeleteIcon height={40} width={40} onClick={() => { dispatchReducer({type: "DELETE", snnRule: snnRule}) }} /></CCol>
        </CRow>
      </RobotoMonoRgDataCell>
    }
      <RobotoMonoRgDataCell type="view">
          <RightArrowLgIcon onClick={() => { dispatchReducer({type: "VIEW", snnRule: snnRule}) }} width={"31px"} height={"31px"} />
      </RobotoMonoRgDataCell>
  </CTableRow>
};

export default SNNRulesTableItem;
