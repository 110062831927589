import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import _ from 'lodash';
import GenericTable from '../../components/AdminDashboard/GenericTable';
import HeadTitle from '../../components/AdminDashboard/HeadTitle';
import KeyValueContent from '../../components/AdminDashboard/KeyValueContent';
import {getUOOeNotam, getUONsuNotam, postUoNotamFile} from '../../services/bekolDispatchAPI';
import {getCXOeNotam, getCXNsuNotam} from '../../services/flightPlanConfigAPI'
import {
    selectDispatcher,
    resetOeNotam,
    setOeNotam,
    resetCXOeNotam,
    setCXOeNotam,
    resetNsuNotam,
    setNsuNotam,
    resetCXNsuNotam,
    setCXNsuNotam
} from '../../slice/dispatcherSlice';
import {selectUser} from '../../slice/userSlice';
import {FLIGHT_PLAN_CONFIG_PATHS, UO_PATHS} from "../../constants/path";
import {GENERIC_TABLE_TYPES} from '../../constants/genericTableConstant'
import {checkPagePermission} from '../../constants/simConstant';
import moment from "moment/moment";
import {CRow} from "@coreui/react-pro";
import {CXButton} from "../../components/CXForm";
import {postNotamFile} from "../../services/dispatchAPI";
import AddOrEditOFPLightBox from "../../components/Sim/AddOrEditOFPLightBox";
import AppLoadingScreen from "../../components/AppLoadingScreen";

/**
 * Application home page component
 * @returns {JSX}
 */

const SharedNotamPage = () => {
    const {pathname} = useLocation();
    const {oeNotam: uoOeNotam, cxOeNotam, nsuNotam: uoNsuNotam, cxNsuNotam} = useAppSelector(selectDispatcher);
    const {access, userId} = useAppSelector(selectUser);
    const {isAdmin, isUODispatch, isCXDispatch} = access as any;
    const dispatch = useAppDispatch();
    const hasAccessForCX = checkPagePermission([isAdmin, isCXDispatch]);
    const hasAccessForUO = checkPagePermission([isAdmin, isUODispatch]);
    const isCXOeNotam = pathname === FLIGHT_PLAN_CONFIG_PATHS.oeNotam;
    const isCXNsuNotam = pathname === FLIGHT_PLAN_CONFIG_PATHS.nsuNotam;
    const isUOOeNotam = pathname === UO_PATHS.oeNotam;
    const isUONsuNotam = pathname === UO_PATHS.nsuNotam;

    const importNewCsvRef = useRef<HTMLInputElement>(null);
    const [isLoading, setLoading] = useState(false);
    enum ShowTypes {
        headTitle = 'headTitle',
        lastUpdate = 'lastUpdate',
        totalCount = 'totalCount',
    }

    const showHeadTitleArray = [FLIGHT_PLAN_CONFIG_PATHS.oeNotam, FLIGHT_PLAN_CONFIG_PATHS.nsuNotam, UO_PATHS.oeNotam, UO_PATHS.nsuNotam];
    const showLastUpdateArray = [FLIGHT_PLAN_CONFIG_PATHS.oeNotam, FLIGHT_PLAN_CONFIG_PATHS.nsuNotam, UO_PATHS.oeNotam, UO_PATHS.nsuNotam];
    const showTotalCountArray = [FLIGHT_PLAN_CONFIG_PATHS.oeNotam, FLIGHT_PLAN_CONFIG_PATHS.nsuNotam, UO_PATHS.oeNotam, UO_PATHS.nsuNotam];

    const oeNotamColName: Array<string> = [
        "acType",
        "acSubType",
        "text",
        "startz",
        "endz",
        "iata",
        "icao",
    ];
    const nsuNotamColName: Array<string> = [
        "Category",
        "ICAO",
        "Airline",
        "FlightNo",
        "DEP",
        "ARR",
        "StartAt(utc)",
        "EndAt(utc)",
        "Review",
        "Initiator",
        "Text",
        "Remark",
        "CreateBy"
    ];

    interface INotamContainer {
        headTitle: string;
        tableType: string;
        tableColNames: Array<string>;
        tableData: Array<any>;
        lastUpdate?: string;
        totalCount: number;
    }

    let notamConObj: INotamContainer = {
        headTitle: '',
        tableType: '',
        tableColNames: [],
        tableData: [],
        lastUpdate: undefined,
        totalCount: 0,
    }

    const fillNotamData = () => {
        if (isCXOeNotam || isUOOeNotam) {
            notamConObj.tableData = isCXOeNotam ? cxOeNotam : uoOeNotam;
            notamConObj.tableColNames = oeNotamColName;
            notamConObj.headTitle = GENERIC_TABLE_TYPES.oeNotam;
            notamConObj.tableType = GENERIC_TABLE_TYPES.oeNotam;
        } else if (isCXNsuNotam || isUONsuNotam) {
            notamConObj.tableData = isCXNsuNotam ? cxNsuNotam : uoNsuNotam;
            notamConObj.tableColNames = nsuNotamColName;
            notamConObj.headTitle = GENERIC_TABLE_TYPES.nsuNotam;
            notamConObj.tableType = GENERIC_TABLE_TYPES.nsuNotam;
        }
        const notamCreatedList = !!notamConObj.tableData && notamConObj.tableData.map(notam => notam.created);
        const lastItemUpdateTime = !!notamCreatedList && notamCreatedList[notamCreatedList.length - 1];
        notamConObj.lastUpdate = !!lastItemUpdateTime ? moment.utc(new Date(lastItemUpdateTime)).format("DDMMMYYYY HH:mm:ss").toUpperCase() : '--';
        notamConObj.totalCount = notamCreatedList.length;
    }

    const isShow = (pathname, showType) => {
        switch (showType) {
            case ShowTypes.headTitle:
                return showHeadTitleArray.includes(pathname);
            case ShowTypes.lastUpdate:
                return showLastUpdateArray.includes(pathname);
            case ShowTypes.totalCount:
                return showTotalCountArray.includes(pathname);
        }
    }

    useEffect(() => {
        if (isCXOeNotam) {
            dispatch(resetCXOeNotam());
            const getOeNotam = async () => {
                let response;
                if (hasAccessForCX) {
                    response = await getCXOeNotam();
                }

                if (!!response) {
                    dispatch(setCXOeNotam(response));
                }
            }
            getOeNotam();
        } else if (isUOOeNotam) {
            dispatch(resetOeNotam());
            const getOeNotam = async () => {
                let response;
                if (hasAccessForUO) {
                    response = await getUOOeNotam();
                }

                if (!!response) {
                    dispatch(setOeNotam(response));
                }
            }
            getOeNotam();
        } else if (isUONsuNotam) {
            dispatch(resetNsuNotam());
            const getNsuNotam = async () => {
                let response;
                if (hasAccessForUO) {
                response = await getUONsuNotam();
                }
                if (!!response) {
                    dispatch(setNsuNotam(response));
                }
            }
            getNsuNotam();
        } else if (isCXNsuNotam) {
            dispatch(resetCXNsuNotam());
            const getNsuNotam = async () => {
                let response;
                if (hasAccessForCX) {
                    response = await getCXNsuNotam();
                }
                if (!!response) {
                    dispatch(setCXNsuNotam(response));
                }
            }
            getNsuNotam();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const UploadFileHandling = () => {
        return <CXButton
            onClick={() => triggerFileExplorer()}
            text={"Upload File To S3"}
            outline={true}
            fontSize={"14px"}/>
    };

    const triggerFileExplorer = () => {
        if (importNewCsvRef?.current) {
            importNewCsvRef.current.click();
        }
    };

    const refreshPageAfterUpload = () => {
        // Refresh the page after the timeout expires
        setTimeout(() => {
            setLoading(false);
            window.location.reload();
        }, 10000);
    }
    const selectFile = (e) => {
        const fileForSubmit = _.get(e, 'target.files[0]');
        if(!fileForSubmit) return;
        if(fileForSubmit.name !== 'Co-notam_console.csv'){
            alert('Please rename your file as Co-notam_console.csv before submitting.');
            return;
        }
        setLoading(true)
        let bodyFormData = new FormData();
        bodyFormData.append("file", fileForSubmit);
        bodyFormData.append("createdBy", userId);
        if (isCXNsuNotam) {
            postNotamFile(bodyFormData).then(res => {
                if (res.status === 500) {
                    throw res;
                }
                refreshPageAfterUpload();
            }).catch(err => {
                console.log(err);
            });
        } else if (isUONsuNotam) {
            postUoNotamFile(bodyFormData).then(res => {
                if (res.status === 500) {
                    throw res;
                }
                refreshPageAfterUpload();
            }).catch(err => {
                console.log(err);
            });
        }
        // fix: avoid select File not firing
        e.target.value = "";
    };

    fillNotamData();

    return (
        <>
            <div className={"section d-flex justify-content-between"}>
                {isShow(pathname, ShowTypes.headTitle) && <HeadTitle headTitle={notamConObj.headTitle}/>}

                {(isCXNsuNotam || isUONsuNotam) && <CRow className={"p-2 ms-auto"}>
                    <>
                        <AddOrEditOFPLightBox TriggerButton={UploadFileHandling()} heading={"Upload file"}
                                              submitButtonText={"Upload file"}/>
                        <input ref={importNewCsvRef} onChange={selectFile} type="file" accept={".csv"} hidden
                               multiple></input>
                    </>
                </CRow>}
            </div>

            {isShow(pathname, ShowTypes.lastUpdate) &&
                <KeyValueContent title="LastUpdate" content={notamConObj.lastUpdate}/>}
            {isShow(pathname, ShowTypes.totalCount) &&
                <KeyValueContent title="Total Count" content={notamConObj.totalCount}/>}
            <div className={"section"}>
                <GenericTable
                    type={notamConObj.tableType}
                    fieldNames={notamConObj.tableColNames}
                    data={notamConObj.tableData || []}
                />
            </div>
            {isLoading && <AppLoadingScreen />}
        </>
    );
};

export default SharedNotamPage;
