import React from 'react';
import { CNavTitle } from "@coreui/react";
import AppSidebarItem from './AppSidebarItem';
import { ISideBarObject } from '../types/app';

const AppSideBarSection = ({ navTitle, navData }) => {
    return <>
        <CNavTitle className={"sideBarTitle CathaySansBd"}>{navTitle}</CNavTitle>
        {
            navData.map((data: ISideBarObject, index) => {
                return <AppSidebarItem key={`AppSidebarItem${index}`} name={data.name} link={data.link} />
            })
        }
    </>
};

export default AppSideBarSection;