import { FLIGHT_PLAN_CONFIG_API, HTTP_REQUEST_TYPE } from "../constants/api";
import { sendHttpRequest } from "./api";

interface IDefectRemarkSavePayload {
    defectId: string,
    remark: string
}

const getCXOeNotam = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FLIGHT_PLAN_CONFIG_API.GET_CX_OE_NOTAM,
        {}
    );
    return resp;
};

const getCXNsuNotam = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FLIGHT_PLAN_CONFIG_API.GET_CX_NSU_NOTAM,
        {}
    );
    return resp;
};

const getCXDefects = async (type: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FLIGHT_PLAN_CONFIG_API.GET_CX_DEFECTS,
        type !== "ALL" ? {
            type: type
        } : {}
    );
    return resp;
};

const postCXDefectRemarkSave = async (payload: IDefectRemarkSavePayload) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        FLIGHT_PLAN_CONFIG_API.POST_CX_DEFECTS_SAVE,
        {
            ...payload
        }
    );
    return resp;
};

export {
    getCXOeNotam,
    getCXNsuNotam,
    getCXDefects,
    postCXDefectRemarkSave
};

export type { IDefectRemarkSavePayload };