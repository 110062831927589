/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Switch } from "react-router-dom";
import { routeConfig } from "../interfaces/route";
import NotFoundPage from '../pages/NotFoundPage'

interface RouteSwitchProps {
    routes: routeConfig[];
}

const RouteWithSubRoutes = ({
    path,
    routes,
    component: Component,
    exact = true,
}: routeConfig) => (
    <Route
        path={path}
        render={(props) => <Component {...props} routes={routes} exact={exact} />}
    />
);

const RouteSwitch = ({routes}: RouteSwitchProps) => (
    <Switch>
        {routes.map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} />
        ))}
        <Route component={NotFoundPage} />
    </Switch>
);

export default RouteSwitch;