import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import CloseFlightListTableItem from './CloseFlightListTableItem';
import { useAppDispatch } from '../../app/hooks';
import { closeFlightObject } from '../../interfaces/closeFlight';
import { setCloseFlightList } from '../../slice/dispatcherSlice';

interface ICloseFlightListTableProps {
  closeFlightList: Array<closeFlightObject>;
  searchError: string;
  user: string;
}


const CloseFlightListTable = ({ closeFlightList, searchError, user }: ICloseFlightListTableProps) => {
  let sortedCloseFlightList = [...closeFlightList];
  const dispatch = useAppDispatch();

  const dispatchSortAction = () => {
    dispatch(setCloseFlightList({ closeFlightList: sortedCloseFlightList}));
  };

  const EMPTY_RESULT = "There is no close flight on these days.";
  const emptyReason = searchError || EMPTY_RESULT;

  return <CTable borderless hover>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
          sortedCloseFlightList.sort((a, b) => { return a.ufi.localeCompare(b.ufi, undefined, { numeric: true }) });
          dispatchSortAction();
        }}>
          <span className={"CathaySansRg"} style={{  fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "4C4C4C"}}>
            ufi
          </span>
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
          sortedCloseFlightList.sort((a, b) => { return a.reg.localeCompare(b.reg, undefined) });
          dispatchSortAction();
        }}>
          <span className={"CathaySansRg"} style={{  fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "4C4C4C"}}>
            reg
          </span>
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
          sortedCloseFlightList.sort((a, b) => { return a.type.localeCompare(b.type, undefined) });
          dispatchSortAction();
        }}>
          <span className={"CathaySansRg"} style={{  fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "4C4C4C"}}>
            Close flight type
          </span>
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
          sortedCloseFlightList.sort((a, b) => new Date(b.std).valueOf() - new Date(a.std).valueOf());
          dispatchSortAction();
        }}>
          <span className={"CathaySansRg"} style={{  fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "4C4C4C"}}>
            STD
          </span>
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
          sortedCloseFlightList.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf());
          dispatchSortAction();
        }}>
          <span className={"CathaySansRg"} style={{  fontWeight: 400, fontSize: "14px", lineHeight: "18px", color: "4C4C4C"}}>
            Created time
          </span>
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      {(!!sortedCloseFlightList && sortedCloseFlightList.length !== 0 && !searchError) ?
        (sortedCloseFlightList.map((closeFlight, index) => <CloseFlightListTableItem key={`CloseFlightListTableItem${index}`} {...closeFlight} index={index} totalCount={sortedCloseFlightList.length} user={user} />)) :
        (<CTableRow>
          <CTableDataCell align={"middle"} colSpan={5} className={"CathaySansRg text-center"} style={{ fontSize: "25px", height: "200px", fontWeight: 'bold' }}>{emptyReason}</CTableDataCell>
        </CTableRow>)}
    </CTableBody>
  </CTable>
};

export default CloseFlightListTable;