
interface EdgFile {
  filename: string;
  URL: string;
  matched: boolean;
}

interface EDGDetail {
  edg_generated: boolean;
  generated_time: string;
  received_time: string;
  generate_duration: string;
  edg_file: EdgFile[];
}

interface EDGComparisonObject {
  AWS: EDGDetail;
  Azure: EDGDetail;
}

const edgComparisonInitialState: EDGComparisonObject = {
  AWS: {
    edg_generated: false,
    generated_time: "",
    received_time: "",
    generate_duration: "",
    edg_file: [
      {
        filename: "",
        URL: "",
        matched: false
      }
    ]
  },
  Azure: {
    edg_generated: false,
    generated_time: "",
    received_time: "",
    generate_duration: "",
    edg_file: [
      {
        filename: "",
        URL: "",
        matched: false
      }
    ]
  }
};

export type { EDGComparisonObject, EDGDetail, EdgFile };
export { edgComparisonInitialState };