import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import { CRow, CCol, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import NoDataTableItem from '../../components/AdminDashboard/NoDataTableItem';
import { DISPATCHER_PATHS, UO_PATHS } from '../../constants/path';
import { getUOClosedFlightDetail } from '../../services/bekolDispatchAPI';
import { selectDispatcher, setCloseFlightDetail } from '../../slice/dispatcherSlice';
import moment from 'moment';
import AppSectionOutline from '../../components/AppSectionOutline';
import { CenteredRightArrowIcon, DownArrowIcon, RightArrowIcon } from '../../assets/images';
import { Col } from 'react-bootstrap';
import { GOOGLE_MAP_API } from '../../constants/api';
import FixedImage from '../../components/AdminDashboard/FixedImage';
import { getResource } from '../../services/api';
import { ufiFormatter } from '../../utils/ufiHelper';
import CloseFlightDetailContent from '../../components/AdminDashboard/CloseFlightDetailContent';
import { checkPagePermission } from '../../constants/simConstant';

/**
 * Application home page component
 * @returns {JSX}
 */

const UOCloseFlightDetailPage = () => {
  const params = useParams()
  const { ufi, closeFlightPackageIdentifier } = params;
  const dispatch = useAppDispatch();
  const { access } = useAppSelector(selectUser);
  const { closeFlightDetail } = useAppSelector(selectDispatcher);
  const { isAdmin, isUODispatch } = access as any;

  useEffect(() => {
    if (checkPagePermission([isAdmin, isUODispatch])) {
      getUOClosedFlightDetail(ufi).then(res => {
        dispatch(setCloseFlightDetail(res));
      }).catch(err => {
        console.log(err);
      });
    } else {
      alert("You have no permission.\nPlease contract admin to grant access.");
    }
  }, [ufi, dispatch, isAdmin, isUODispatch]);

  return (
    <>
      <CloseFlightDetailContent user={"uo"} ufi={ufi} closeFlightPackageIdentifier={closeFlightPackageIdentifier} closeFlightDetail={closeFlightDetail}/>
    </>
  );
};

export default UOCloseFlightDetailPage;