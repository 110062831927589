const characterOnlyInput = (input) => {
  let characterOnlyChecker = new RegExp(/^[a-zA-Z]+$/);
  if(characterOnlyChecker.test(input)){
    return true;
  }else{
    return false;
  }
};

const numberOnlyInput = (input) => {
  let numberOnlyChecker = new RegExp(/^\d+$/);
  if(numberOnlyChecker.test(input)){
    return true;
  }else{
    return false;
  }
};

const numberOnlyInputOrNull = (input) => {
  let numberOnlyChecker = new RegExp(/^\d+$/);
  if(numberOnlyChecker.test(input) || input === ""){
    return true;
  }else{
    return false;
  }
};

const characterAndNumberOnlyInput = (input) => {
  let characterAndNumberOnlyChecker = new RegExp(/^[A-Z0-9]+$/);
  if(characterAndNumberOnlyChecker.test(input)){
    return true;
  }else{
    return false;
  }
};

export {
  characterOnlyInput,
  numberOnlyInput,
  numberOnlyInputOrNull,
  characterAndNumberOnlyInput
};