import axios from "axios";
import qs from "qs";
import { isConstructorDeclaration } from "typescript";
import { v4 as uuidv4 } from "uuid";

import { store } from "../app/store";
import { getAccessToken, loginRedirect, logoutRedirect } from "../config/authConfig";
import { AUTH_API, HTTP_REQUEST_TYPE, RESOURCE_API, PORT_API } from "../constants/api";
import { setAppFlags } from "../slice/appSlice";
import { IDownloadPortFileRequest } from "../slice/dispatcherSlice";
import { setUser, UserSlice } from "../slice/userSlice";
import serverError from "./serverError";
import _ from "lodash";

let user: UserSlice | null = null;

let initInProgress = false;

export const clientId = uuidv4();
console.debug("CLIENT_ID", clientId);

const instance = axios.create({
  baseURL: window?.config?.REACT_APP_API_URL || process.env.REACT_APP_API_URL || "",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 15000, // Server Timeout in 15 second
});

instance.interceptors.request.use(async (config) => {
  const state = store.getState();
  const { userName, clientId } = state.auth;
  if(!config?.headers){
    return config;
  }

  // get accessToken on each api call
  let accessToken = await getAccessToken();

  if (clientId && accessToken && userName) {
    config.headers["x-authenticated-userid"] = userName;
    config.headers["x-eff-client-id"] = clientId;
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  } else {
    delete config.headers.Authorization;
  }

  if (process.env.REACT_APP_BEKOL_API_URL && config.url?.includes(process.env.REACT_APP_BEKOL_API_URL)) {
    config.headers["X-applicationName"] = "EFF";
  }
  return config;
});

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(() => resolve(null), ms));
}

async function waitForInitToComplete() {
  if (!initInProgress) {
    return;
  }
  await delay(500);
  await waitForInitToComplete();
  return;
}

async function initClientApi(token) {
  if (initInProgress) {
    await waitForInitToComplete();
    // init completed - continue
    return;
  }
  initInProgress = true;
  // assume call by getHttpRequestResp each time when there is no user;
  // const config = getAuthHeader();
  // const response = await instance.get(AUTH_API.init, config);
  try {
    instance.interceptors.request.use((config) => {
      if(!config?.headers){
        return config;
      }

      config.headers["x-ms-id-token"] = token;
      return config
    });
    const response: any = await instance.get(AUTH_API.INIT);

    const { status: httpStatus, data: result } = response;
    if (httpStatus === 200) {
      user = result.user;
      // TODO: save role list / other user info in session to redux
      if (user) {
        let noAccess = Object.keys(user.access).length === 0;
        // let noAccess = Object.values(user.access).every(permission => permission === false);
        if(noAccess){
            alert(`Permission Not Granted. \nPlease contact administrator.`);
        }
        store.dispatch(setUser(user));
        store.dispatch(setAppFlags({ field: "isLoading", value: false }));
      }
    }
  } finally {
    initInProgress = false;
  }
  // error throw outside getHttpRequestResp
}

const getHttpRequestResp = async (
  method: string,
  path: string,
  payload?: any,
  isFormData?: boolean,
  timeout?: number
) => {
  let response;
  try {
    if (method === HTTP_REQUEST_TYPE.get) {
      let combinedPath;
      if(!!payload?.responseType && payload.responseType === 'blob'){
        delete payload.responseType;
        combinedPath = `${!!Object.keys(payload).length ? `${path}?${qs.stringify(payload)}` : `${path}`}`;
        response = await instance.get(combinedPath, {"responseType": 'blob'});
      } else {
        combinedPath = `${!!Object.keys(payload).length ? `${path}?${qs.stringify(payload)}` : `${path}`}`;
        response = await instance.get(combinedPath);
      }
    } else if (method === HTTP_REQUEST_TYPE.post) {
      // instance.interceptors.request.use((config) => {
      //   if(!config?.headers){
      //     return config;
      //   }
  
      //   config.headers["Content-Type"] = (isFormData)? "multipart/form-data" : "application/json";
      //   return config
      // });
      let postConfig = {
        headers: {
          'Content-Type': (isFormData)? 'multipart/form-data': 'application/json',
        },
        timeout: timeout || instance.defaults.timeout,
      };
      if(!!payload?.responseType && payload.responseType === 'blob'){
        delete payload.responseType;
        response = await instance.post(path, payload, {...postConfig, ...{"responseType": 'blob'}});
      } else {
        response = await instance.post(path, payload, postConfig);
    }}
     else if (method === HTTP_REQUEST_TYPE.delete) {
      response = await instance.delete(path, { data: payload });
    }
  } catch (err: any) {
    if (err.response) {
      response = {
        status: err.response.status,
        data: err.response.data
      };
    } else {
      // other error - may or may not have response
      response = {
        status: 500,
        data: {
          errors: {
            status: 500,
            title: "Internal Server Error",
            detail: JSON.stringify(err),
          }
        }
      };
    }
  }

  const { status: httpStatus, data: result } = response;
  if (httpStatus >= 200 && httpStatus < 300) {
    return result;
  } else if(httpStatus === 403) {
    alert("The session has expired.\nPlease login again.");
    logoutRedirect();
  } else if(httpStatus === 500) {
    return response;
  }
  throw new serverError(result);
};

let requestCount = 0;
// TODO: this isFormData param setting is temporary solution for file upload, need to review and update later
const sendHttpRequest = (
  method: string,
  path: string,
  payload?: any,
  needLoading = true,
  isFormData?: boolean,
  timeout?: number
) => {
  requestCount++;
  if (needLoading) {
    store.dispatch(setAppFlags({ field: "isLoading", value: true }));
  }
  const promise = getHttpRequestResp(method, path, payload, isFormData,timeout);

  return promise
    .then((data) => [null, data])
    .catch((err) => {
      // TODO: error handling
      if (err instanceof serverError) {
        return [null, err];
      }
      return [null, err];
    })
    .finally(() => {
      requestCount--;
      if (needLoading && requestCount === 0) {
        store.dispatch(setAppFlags({ field: "isLoading", value: false }));
      }
    });
};

const getResource = async (user, type, key, bucket?) => {
  let requestBody = {
    responseType: 'blob',
    source: user,
    type,
    key,
  }
  if(bucket){
    requestBody["bucket"] = bucket;
  }
  const [, resp] = await sendHttpRequest(
    HTTP_REQUEST_TYPE.get,
    `${RESOURCE_API}`,
    requestBody,
);
return resp;
};

const getPortAttachment = async (downloadPortFileRequest: IDownloadPortFileRequest) => {
  let requestBody = {
    ...downloadPortFileRequest,
    responseType: 'blob'
  }

  const [, resp] = await sendHttpRequest(
    HTTP_REQUEST_TYPE.post,
    PORT_API.POST_PORT_DOWNLOAD_FILE,
    requestBody,
);

return resp;
};

// Handles response data.
// A boolean indicating if the request was successful. It's considered successful if the status code is between 200-299 or if there's no status code.
const handleRequestData = (resp: any): [boolean, string, any, any] => {
  const [detail, status] = [resp?.detail, resp?.status, resp?.title];
  const isSucceed = !status || (status && status >= 200 && status < 300);
  // handle error message like status 500 from BE
  let errorMsg = detail;
  if(!detail && resp?.data && typeof resp.data === 'object'){
    errorMsg = _.get(resp, 'data.errors.detail', '');
  }
  return [isSucceed, errorMsg, resp, status];
};

export { initClientApi, sendHttpRequest, getResource, getPortAttachment, handleRequestData };

