import React, {useEffect, useState} from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import { IAppendixFile, setAppendixFiles } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';
import AppendixRulesFileItem from './AppendixRulesFileItem';

interface IAppendixFilesTableProps {
  appendixFiles: IAppendixFile[];
  dispatchReducer: React.Dispatch<any>;
  readOnly: boolean;
  type: string;
  deleteFileRefresh: Function; 
}


const AppendixFilesTable = ({ appendixFiles, dispatchReducer, readOnly, type, deleteFileRefresh }: IAppendixFilesTableProps) => {
  const [fileList, setFileList] = useState<any[]>([]);

  const sortList = (inputList: any[], sortBy: string)=>{
    if(inputList.length === 0) return;
    let sortedList = [...inputList];
    switch (sortBy) {
      case "date":
        sortedList.sort((a, b) => new Date(b.uploadDate).valueOf() - new Date(a.uploadDate).valueOf());
        break;
      case "pdfName":
        sortedList.sort((a, b) => { return a.pdfName.localeCompare(b.pdfName, undefined, { numeric: true }) });
        break;
      default:
        console.warn("sort is not valid");
        break;
    }
    setFileList(sortedList);
  }

  useEffect(()=>{
    sortList(appendixFiles, "date");
  },[appendixFiles])

  return <CTable borderless hover>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {
          sortList(fileList, "pdfName")
        }}>
          File name
        <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"clickable"} onClick={() => {sortList(fileList, "date")}}>
          Upload date
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable"} style={{ fontSize: "16px", lineHeight: "22px", letterSpacing: "0.3px" }}>
          Action
        </CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
        {!!fileList && fileList.length !==0 ? 
            fileList.map((appendixFile, index) => {
              return <AppendixRulesFileItem 
                key={`AppendixFilesTableItem${index}`} 
                appendixFile={appendixFile} 
                dispatchReducer ={dispatchReducer}
                readOnly={readOnly}
                type={type}
                deleteFileRefresh={deleteFileRefresh}/>
            }) 
    
            : 
            (
              <NoDataTableItem colSpan={11} message={"There is no Appendix Files"} />
            ) 
        }
    </CTableBody>
  </CTable>
};

export default AppendixFilesTable;