import React, { useEffect, useState, useRef } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import { CXButton } from '../../components/CXForm';
import { useLocation } from 'react-router-dom';
import { CCol, CRow } from '@coreui/react-pro';
import { AlertObject } from '../../slice/dispatcherSlice';
import NoteContentView from '../../components/AdminDashboard/NoteContentView';
import { getDispatchNotes, updateDispatchNote, createDispatchNote, deleteDispatchNote } from '../../services/notesAPI';
import { AlertIcon } from '../../assets/images';

/**
 * Application home page component
 * @returns {JSX}
 */

const NotesPage = () => {
	const location = useLocation();
	const switchType = () => {
		switch (location.pathname) {
			case "/policy/noFlyZones":
				return "No fly zones";
			case "/policy/tankering":
				return "Tankering policies";
			case "/policy/volcanicAsh":
				return "Volcanic ash";
			case "/policy/fuel":
			default:
				return "Fuel policies";
		}
	}

	const [isShowEditor, setIsShowEditor] = useState<boolean>(false);
	const [editNoteIndex, setEditNoteIndex] = useState<number>(0);
	const [noteType, setNoteType] = useState<string>(switchType());
	const { userId } = useAppSelector(selectUser);
	const [notesList, setNotesList] = useState<{
		_id: 				string,
		type: 			string,
		title: 			string,
		text: 			string,
		isActive: 	boolean,
		galacxyId: 	string,
		activeBy: 	string,
		cancelBy: 	string,
		updateBy: 	string,
		alert: 			AlertObject[],
		activeAt: 	string,
		cancelAt: 	string,
		createdAt: 	string,
		updatedAt: 	string
	}[]>([]);

	useEffect(() => {
		getNotes(noteType);
	}, [])

	const getNotes = (noteType: string) => {
		getDispatchNotes(noteType).then(res => {
		setNotesList(res.noteList);
		}).catch(err => {
			console.log(err);
		});
	}

	const onClickCreateNote = () => {
		createDispatchNote({type: noteType, galacxyId: userId, title: "", text: "", updateBy: userId })
		.then((res) => {
			if (!res.createResponse) {
        throw res;
      }
			getNotes(noteType);
		})
		.catch((err) => {
			console.log(err);
		});
  }

	const clickEditNote = (index: number) => {
		setIsShowEditor(true);
		setEditNoteIndex(index);
  }

	const resetModal = () => {
		setIsShowEditor(false);
		setEditNoteIndex(-1);
  }
	
	const clickDeleteNote = (index: number) => {
		if (window.confirm(`Are you sure to delete this Note?`)) {
      deleteDispatchNote(notesList[index]._id)
      .then((res) => {
				getNotes(noteType);
				if (res.deleteResponse == "") {
					throw res;
				}
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

	const updateNote = (updatedId, updatedObj) => {
		updateDispatchNote(updatedId, updatedObj)
		.then((res) => {
			getNotes(noteType);
			if (!res.updateResponse) {
        throw res;
      }
		})
		.catch((err) => {
			window.alert(err.data.errors.detail);
			console.log(err);
		});
  }

	const onUpdateText = (_id: string, title: string, value: string) => {
		updateNote(_id, {title: title, text: value, updateBy: userId, updatedAt: new Date().toISOString().toString()});
  }
	
	const sendAlert = (index: number) => {
		notesList[index].alert.push({
			send: userId,
			receive: [],
			alertAt: new Date().toISOString().toString()
		});

		updateNote(notesList[index]._id, {alert: notesList[index].alert});
  }

	const activateNote = (index: number, act: string) => {
		const updatedObj = act === 'cancel' ? 
			{cancelBy: userId, cancelAt: new Date().toISOString().toString(), isActive: false} :
			{activeBy: userId, activeAt: new Date().toISOString().toString(), isActive: true}
		updateNote(notesList[index]._id, updatedObj);
  }


	return (
		<>
			<div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>{noteType}</div>
			<CRow>
				<CCol style={{textAlign: "right", lineHeight: "48px"}}>
					<CXButton
						text={"Add new policy"}
						onClick={() => onClickCreateNote()}
						fontSize={"18px"}
						width={"200px"}
						/>
				</CCol>
			</CRow>
      <CRow className='infoBorderRow'>
        <CCol sm={6} >
          <AlertIcon style={{ backgroundColor: "white", height: "2.4rem", width: '2.4rem', margin: "10px 0 0 20px", position: "absolute" }} />
          <span style={{ fontSize: "16px", fontFamily: "CathaySans_Bd", color: "#4C4C4C", fontWeight: "700", lineHeight: "20px", position: "absolute", margin: "22px 0 0 70px" }} >
            Sub-bullet points can only be input in the next indent level
          </span>
          <span style={{ fontSize: "16px", fontFamily: "CathaySans_Bd", color: "#7C7C7C", fontWeight: "400", lineHeight: "20px", position: "absolute", margin: "42px 0 0 70px" }} >
            The bullet points that skip one or more indent level will not be able to display.
          </span>
        </CCol>
      </CRow>
			<CRow>
				{notesList && notesList.map((note, index) => (
					<NoteContentView
					index={index}
					note={note}
					editNoteIndex={editNoteIndex}
					isShowEditor={isShowEditor}
					clickEditNote={clickEditNote}
					resetModal={resetModal}
					clickDeleteNote={clickDeleteNote}
					onUpdateText={onUpdateText}
					sendAlert={sendAlert}
					activateNote={activateNote}
					/>
				)
				)}
			</CRow>
		</>
	);
};

export default NotesPage;