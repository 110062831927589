import { CCol, CRow } from '@coreui/react-pro';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import GenericTable from '../../components/AdminDashboard/GenericTable';
import CXButton from '../../components/CXForm/CXButton';
import CXDatePicker from '../../components/CXForm/CXDatePicker';
import CXDropDown from '../../components/CXForm/CXDropDown';
import CXTextInput from '../../components/CXForm/CXTextInput';
import UOCrewlistCard from '../../components/UOCrewlistCard';
import { GENERIC_TABLE_TYPES } from '../../constants/genericTableConstant';
import { checkPagePermission } from '../../constants/simConstant';
import { getUOCrewlists, getUODefects } from '../../services/bekolDispatchAPI';
import { resetDefects, selectDispatcher, setDefects } from '../../slice/dispatcherSlice';
import { selectUser } from '../../slice/userSlice';

/**
 * Application home page component
 * @returns {JSX}
 */


const UOCrewlistPage = () => {
  const { access } = useAppSelector(selectUser);
  const { isAdmin, isUODispatch } = access as any;
  const [displayDate, setDisplayDate] = useState(
    moment().startOf("day").add(8, "hours").toISOString()
  );

  const [flightNo, setFlightNo] = useState<string>("");
  const [crewlists, setCrewlists] = useState<any>([]);

  const search = useCallback(() => {
    if (checkPagePermission([isAdmin, isUODispatch])) {
        getUOCrewlists(displayDate, flightNo)
        .then((res) => {
            res.sort((a, b) => moment(b.stdUTC).diff(moment(a.stdUTC)));
            setCrewlists(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("You have no permission.\nPlease contract admin to grant access.");
    }
  }, [displayDate, isAdmin, isUODispatch, flightNo]);

  return (
    <>
      <div
        className={"CathaySansBd"}
        style={{ fontSize: "32px", lineHeight: "36px" }}
      >
        Crewlist
      </div>
      <CRow>
        <CCol sm={2}>
          <CXDatePicker
            placeholder={"STD"}
            value={displayDate}
            onChange={setDisplayDate}
            zIndex={1029}
          />
        </CCol>
        <CCol sm={2}>
        <CXTextInput
            value={flightNo}
            placeholder={"Flight No"}
            onChange={setFlightNo} />
        </CCol>
        <CCol sm="auto">
          <CXButton
            outline={false}
            text={"Search"}
            onClick={() => search()}
            fontSize={"14px"}
          />
        </CCol>
      </CRow>
        {crewlists.map((crewlist)=> {
            return <UOCrewlistCard externalCrewlist={crewlist}/>
        })}
    </>
  );
};

export default UOCrewlistPage;
