import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import { IAppendixRule, setAppendixRules } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import AppendixRulesTableItem from './AppendixRulesTableItem';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';

interface IAppendixRulesTableProps {
  appendixRules: IAppendixRule[];
  dispatchReducer: React.Dispatch<any>;
  readOnly: boolean;
}


const AppendixRulesTable = ({ appendixRules, dispatchReducer, readOnly }: IAppendixRulesTableProps) => {
  let sortedAppendixRules = [...appendixRules];
  const dispatch = useAppDispatch();

  const dispatchSortAction = () => {
    dispatch(setAppendixRules(sortedAppendixRules));
  };

  const sortByLocale = (key: string) => {
    sortedAppendixRules.sort((a, b) => { return a[key].localeCompare(b[key], undefined, { numeric: true }) });
    dispatchSortAction();
  }

  return <CTable className="appendixTable" borderless hover>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"} onClick={() => {
                    }}>
                        <div>
                    Airline
                    </div>
                    <div>
                    Flt no
                    </div>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("aircraftType")
                    }}>
                    A/C type
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortByLocale("registration")
                    }}>
                    Reg
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortedAppendixRules.sort((a, b) => { return a.dep[0].localeCompare(b.dep[0], undefined, { numeric: true }) });
                        dispatchSortAction();
                    }}>
                    Ori
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                    sortedAppendixRules.sort((a, b) => { return a.arr[0].localeCompare(b.arr[0], undefined, { numeric: true }) });
                        dispatchSortAction();
                    }}>
                        Dest
                        <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"} onClick={() => {
                    }}>
                        <div>
                    ALTN
                    </div>
                    <div>
                    ERA
                    </div>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortedAppendixRules.sort((a, b) => new Date(b.expiry).valueOf() - new Date(a.expiry).valueOf());
                        dispatchSortAction();
                    }}>
                        Expiry
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortedAppendixRules.sort((a, b) => new Date(b.review).valueOf() - new Date(a.review).valueOf());
                        dispatchSortAction();
                    }}>
                        Reviews
                    <DownArrowIcon height={12} width={12} />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable appendixHeader"} onClick={() => {
                        sortedAppendixRules.sort((a, b) => { return a.files.join(",").localeCompare(b.files.join(",")) });
                        dispatchSortAction();
                    }}>
                    <div>
                        Files
                        <DownArrowIcon height={12} width={12} />
                    </div>
                    </CTableHeaderCell>
                    {!readOnly && 
                        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 appendixHeader"}>Action</CTableHeaderCell>
                    }
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {!!appendixRules && appendixRules.length !==0? 
                    appendixRules.map((appendixRule, index) => 
                        <AppendixRulesTableItem 
                            key={`AppendixRulesTableItem_${index}`} 
                            appendixRule={appendixRule}
                            dispatchReducer={dispatchReducer}
                            readOnly={readOnly}/>) 
                    : 
                    (
                        <NoDataTableItem colSpan={10} message={"There is no Appendix Rules"} />
                    ) 
                }
            </CTableBody>
        </CTable>
};

export default AppendixRulesTable;