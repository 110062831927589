import { CCol, CRow } from '@coreui/react-pro';
import React, { useEffect, useState } from 'react';
import { CXButton, CXTextInput } from '../../components/CXForm';
import { DeleteIcon, EditIcon, ConfirmIcon, CancelIcon } from '../../assets/images';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";
import moment from 'moment';
import { AlertObject } from '../../slice/dispatcherSlice';
import '@mdxeditor/editor/style.css'
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, tablePlugin, toolbarPlugin, thematicBreakPlugin, 
  BoldItalicUnderlineToggles, UndoRedo, ListsToggle, BlockTypeSelect, InsertTable, diffSourcePlugin, DiffSourceToggleWrapper,
  markdownShortcutPlugin, frontmatterPlugin } from '@mdxeditor/editor'
import { formatMDXEditorContent } from '../../utils/mdxEditorHelper';

interface NoteContentProps {
  index: number;
  note: {
		_id: 				string,
		type: 			string,
    title:      string,
		text: 			string,
		isActive: 	boolean,
		galacxyId: 	string,
		activeBy: 	string,
		cancelBy: 	string,
		updateBy: 	string,
		alert: 		  AlertObject[],
		activeAt: 	string,
		cancelAt: 	string,
		createdAt: 	string,
		updatedAt: 	string
	};
  editNoteIndex: number;
  isShowEditor: boolean;
  clickEditNote: Function;
  resetModal: Function;
  clickDeleteNote: Function;
  onUpdateText: Function;
  sendAlert: Function;
  activateNote: Function;
}

const NoteContentView = ({ index, note, editNoteIndex, isShowEditor, clickEditNote, resetModal, clickDeleteNote, onUpdateText, sendAlert, activateNote }: NoteContentProps) => {
  
  const [editTitle, setEditTitle] = useState<string>("");
  const [editValue, setEditValue] = useState<string>("");
  const tableStyles = `
    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
      word-wrap: break-word;
    }
  `;

  const formatAlert = (alert) => {
    if (!alert.length) return '';
    let alertStr = '';
    let alertArr = alert.length > 3 ? alert.slice(alert.length - 3) : alert;
    alertArr.map((a) => {
      alertStr = alertStr + `Alert sent on ${moment.utc(a.alertAt).format("DD MMM YYYY HH:mm") || "-"} // `
    });
    return alertStr
  }

  const onChangeEditor = (content: string) => {
    const contentFormatted = formatMDXEditorContent(content);
    setEditValue(contentFormatted);
  };

  const onClickEdit = () => {
    setEditTitle(note.title);
    setEditValue(note.text);
    clickEditNote(index);
  };

  const onClickCancel = () => {
    if (window.confirm(`Are you sure to cancel edit?`)) {
      resetModal();
    }
  };

  const onClickConfirm = () => {
    if (editTitle) {
      onUpdateText(note._id, editTitle, editValue);
      resetModal();
    } else {
      alert(`Title may not be blank`);
    }
  };

  const onClickAlert = () => {
    if (window.confirm(`Are you sure to send alert?`)) {
      sendAlert(index);
    }
  };

  const onClickActivate = () => {
    const act = note.isActive ? "cancel" : "activate";
    if (window.confirm(`Are you sure to ${act} policy?`)) {
      activateNote(index, act);
    }
  };

  return (
    <>
    <div style={!note.isActive ? 
      { marginTop: "50px", backgroundColor: "#E6E7E8", minHeight: "130px", borderRadius: "12px", paddingBottom:"15px"} : 
      { marginTop: "50px", backgroundColor: "#F5F5F5", minHeight: "130px", borderRadius: "12px", paddingBottom:"15px", borderLeftColor: "#116F9A", borderLeftWidth: "15px", borderLeftStyle: "solid" }}>
        
      {!note.isActive &&
        <CRow>
          <span style={{ marginLeft: "20px", fontFamily: "Roboto Mono", fontWeight: "700", fontSize: "20px", lineHeight: "24px", color: "#4C4C4C", marginTop: "20px"}}>[Cancelled]</span>
        </CRow>
      }

      {note.title && (!isShowEditor || (editNoteIndex !== index)) &&
        <CRow>
          <span style={{ marginLeft: "20px", fontFamily: "Roboto Mono", fontWeight: "700", fontSize: "20px", lineHeight: "24px", color: "#4C4C4C", marginTop: "10px", width: "84%", wordWrap: "break-word"}}>{note.title}</span>
        </CRow>
      }
      {isShowEditor && editNoteIndex === index &&
        <CRow>
          <CCol sm="10" style={{ marginLeft: "18px", marginTop: "18px", padding: "0px" }}>
            <CXTextInput placeholder={"Title"} value={editTitle} onChange={setEditTitle} zIndex={1}/>
          </CCol>
        </CRow>
      }
      
      <CRow>
        {(!isShowEditor || (editNoteIndex !== index)) && <CCol sm={10} style={{ marginLeft: "20px", marginTop: "18px", marginBottom: "20px" }}>
          <style>{tableStyles}</style>
          <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{note.text}</ReactMarkdown>
				</CCol>}
        {isShowEditor && editNoteIndex === index && <CCol sm={10} style={{ marginLeft: "20px", marginTop: "18px", marginBottom: "20px", background: "#FFFFFF", padding: "0px" }}>
        <MDXEditor
          onChange={(e) => onChangeEditor(e)}
          markdown={editValue}
          plugins={[
            listsPlugin(),
            headingsPlugin(),
            quotePlugin(),
            tablePlugin(),
            thematicBreakPlugin(),
            diffSourcePlugin({ diffMarkdown: note.text }),
            markdownShortcutPlugin(),
            frontmatterPlugin(),
            toolbarPlugin({
              toolbarContents: () => ( <> <DiffSourceToggleWrapper><UndoRedo /><BoldItalicUnderlineToggles /><ListsToggle /><BlockTypeSelect /><InsertTable /></DiffSourceToggleWrapper></>)
            }),
          ]}
        />
        </CCol>}


        {!isShowEditor && <CCol style={{ position: "relative", marginTop: "20px" }}>
          <EditIcon style={{ height: "3rem", width: '3rem', cursor: "pointer", position: "absolute", right: 100, top: 0}} onClick={() => onClickEdit()} />
          <DeleteIcon style={{ height: "3rem", width: '3rem', cursor: "pointer", position: "absolute", right: 30, top: 0}} onClick={() => clickDeleteNote(index)} />
        </CCol>}
        {(isShowEditor && (editNoteIndex !== index)) && <CCol style={{ position: "relative", marginTop: "20px" }}>
          <EditIcon style={{ backgroundColor: "#EFEFEF", height: "3rem", width: '3rem', position: "absolute", right: 100, top: 0}}/>
          <DeleteIcon style={{ backgroundColor: "#EFEFEF", height: "3rem", width: '3rem', position: "absolute", right: 30, top: 0}} />
        </CCol>}
        {(isShowEditor && (editNoteIndex === index)) && <CCol style={{ position: "relative", marginTop: "20px" }}>
          <ConfirmIcon style={{ height: "40px", width: '40px', cursor: "pointer", position: "absolute", right: 100, top: 0}} onClick={() => onClickConfirm()} />
          <CancelIcon style={{ height: "40px", width: '40px', cursor: "pointer", position: "absolute", right: 40, top: 0}} onClick={() => onClickCancel()} />
        </CCol>}
      </CRow>
      {!isShowEditor && 
        <CRow>
          <CCol>
            <span style={{ marginLeft: "20px", fontSize: "18px", fontFamily: "CathaySans_Rg", color: "#808285", fontWeight: "400", lineHeight: "28px" }} >
              Created on {moment.utc(note.createdAt).format("DD MMM YYYY HH:mm") || "-"} by <span style={{ fontFamily: "CathaySans_Bd", color: "#808285", fontWeight: "700" }} >{note.galacxyId}</span> // 
            </span>
            <span style={{ fontSize: "18px", fontFamily: "CathaySans_Rg", color: "#808285", fontWeight: "400", lineHeight: "28px", marginLeft: "10px" }} >
              Last updated on {moment.utc(note.updatedAt).format("DD MMM YYYY HH:mm") || "-"} by <span style={{ fontFamily: "CathaySans_Bd", color: "#808285", fontWeight: "700" }} >{note.updateBy}</span> // 
            </span>
          </CCol>
        </CRow>
      }
      {!isShowEditor && !note.isActive &&
        <CRow>
          <span style={{ marginLeft: "20px", fontSize: "18px", fontFamily: "CathaySans_Rg", color: "#808285", fontWeight: "400", lineHeight: "28px" }} >
            Cancel policy on {moment.utc(note.cancelAt).format("DD MMM YYYY HH:mm") || "-"} by <span style={{ fontFamily: "CathaySans_Bd", color: "#808285", fontWeight: "700" }} >{note.cancelBy}</span> // 
          </span>
        </CRow>
      }
      {!isShowEditor && 
        <CRow className="buttonRow">
          <CCol sm={2}>
            <CXButton text={"Send Alert"} onClick={() => onClickAlert()} fontSize={"16px"} width={"100%"} outline={true} />
          </CCol>
          <CCol sm={6}></CCol>
          <CCol sm={2} style={{marginRight: "100px"}}>
            {!note.isActive &&
              <CXButton text={"Activate policy"} onClick={() => onClickActivate()} fontSize={"16px"} width={"100%"} outline={true} />
            }
            {note.isActive &&
              <CXButton text={"Cancel policy"} onClick={() => onClickActivate()} fontSize={"16px"} width={"100%"} outline={true} />
            }
          </CCol>
        </CRow>
      }
      {!isShowEditor && formatAlert(note.alert) &&
        <CRow>
          <CCol>
            <span style={{ marginLeft: "20px", fontSize: "18px", fontFamily: "CathaySans_Rg", color: "#808285", fontWeight: "400", lineHeight: "28px" }} >
              {formatAlert(note.alert)}
            </span>
          </CCol>
        </CRow>
      }
    </div>   
    </>         
    )
};

export default NoteContentView;