import {DISPATCHER_PATHS, UO_PATHS} from "../../constants/path";
import {routeConfig} from "../../interfaces/route";
import UOHKEAppendixRulePage from "./UOHKEAppendixRulePage";
import UOCloseFlightDetailPage from "./UOCloseFlightDetailPage";
import UOCrewlistPage from "./UOCrewlistPage";
import UODefectPage from "./UODefectPage";

import UODispatcherPage from "./UODispatchPage";
import UONsuNotamPage from "./UONsuNotamPage";
import UOOeNotamPage from "./UOOeNotamPage";
import UOSearchCloseFlightPage from "./UOSearchCloseFlightPage";
import UOSnnRulesPage from "./UOSnnRulesPage";
import UOFuelAdvisoryConfigPage from "./UOFuelAdvisoryConfigPage";
import SharedNotamPage from "../shared/SharedNotamPage";


const dispatchUORouterConfig: routeConfig[] = [
  {
    path: UO_PATHS.flightPlan,
    component: UODispatcherPage,
    exact: true,
  }, {
    path: UO_PATHS.snnRule,
    component: UOSnnRulesPage,
    exact: true,
  }, {
    path: UO_PATHS.defect,
    component: UODefectPage,
    exact: true,
  }, {
    path: UO_PATHS.fuelAdv,
    component: UOFuelAdvisoryConfigPage,
    exact: true,
  }, {
    path: UO_PATHS.crewlist,
    component: UOCrewlistPage,
    exact: true,
  }, {
    path: UO_PATHS.nsuNotam,
    component: SharedNotamPage,
    exact: true,
  }, {
    path: UO_PATHS.oeNotam,
    component: SharedNotamPage,
    exact: true,
  }, {
    path: UO_PATHS.appendixRule,
    component: UOHKEAppendixRulePage,
    exact: true,
  }, {
    path: UO_PATHS.closeFlight,
    component: UOSearchCloseFlightPage,
    exact: true,
  }, {
    path: UO_PATHS.closeFlightDetail,
    component: UOCloseFlightDetailPage,
    exact: true,
  }
  // ,{
  //     path: UO_PATHS.flightDetail,
  //     component: FlightDetailPage,
  // }
];

export default dispatchUORouterConfig;
