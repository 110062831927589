import { FLIGHT_POLICY_PATHS } from "../../constants/path";
import { routeConfig } from "../../interfaces/route";
import NotesPage from "./NotesPage";


const policyRouterConfig: routeConfig[] = [
    {
        path: FLIGHT_POLICY_PATHS.fuelPolicy,
        component: NotesPage,
    },
    {
        path: FLIGHT_POLICY_PATHS.noFlyZonesPolicy,
        component: NotesPage,
    },
    {
        path: FLIGHT_POLICY_PATHS.tankeringPolicy,
        component: NotesPage,
    },
    {
        path: FLIGHT_POLICY_PATHS.volcanicAshesPolicy,
        component: NotesPage,
    }
];

export default policyRouterConfig;