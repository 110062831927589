import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import './style/EDGSuccessRate.scss';
import { dateFormatter } from '../../../utils/dateHelper';
import { getEDGSuccessRateList } from '../../../services/dispatchAPI';
import { dateFormat } from '../../../constants/dateConstant';

interface EDGSuccessRateItem {
  date: string;
  AWS: string;
  azure: string;
}

const EDGSuccessRateList = () => {
  const defaultChart = '--';
  const edgSuccessRateSection = {
    title: 'EDG Success Rate',
    nameItem: ['Sources', 'AWS', 'Azure'],
  }
  const [edgSuccessRateList, setEDGSuccessRateList] = useState<Array<EDGSuccessRateItem>>([]);

  useEffect(() => {
    fetchEDGSuccessRateList();
  }, []);

  const fetchEDGSuccessRateList = () => {
    getEDGSuccessRateList().then(res => {
      if (res?.status === 500) {
        console.log('get EDG success rate failed, res=', res);
      } else {
        setEDGSuccessRateList(res?.edgSuccessRate || []);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const nameItemStyle = (index: number) => {
    return index === 0 ? 'edg-scs-rate-row-src-date' : 'edg-scs-rate-row-src-value';
  };

  return (
    <div className='edg-scs-rate'>
      <div className={"edg-scs-rate-title"}>
        {edgSuccessRateSection.title}
      </div>
      <div className='mt-2 d-flex justify-content-between'>
        <div>
          {edgSuccessRateSection.nameItem.map((item, index) => (
            <div className={nameItemStyle(index)} key={`edgSuccessRateNameItem${index}`}>{item}</div>
          ))}
        </div>
        {_.isArray(edgSuccessRateList)
          && !_.isEmpty(edgSuccessRateList)
          && edgSuccessRateList.map((item, index) => (
            <div key={`edgSuccessRateListItem${index}`}>
              <div className='edg-scs-rate-row-src-date'>{dateFormatter(item.date, dateFormat) || defaultChart}</div>
              <div className='edg-scs-rate-row-src-value'>{item.AWS || defaultChart}</div>
              <div className='edg-scs-rate-row-src-value'>{item.azure || defaultChart}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EDGSuccessRateList;