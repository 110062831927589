import React from 'react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { DownArrowIcon } from '../../assets/images';
import SNNRulesTableItem from './SNNRulesTableItem';
import { ISNNRule, setSNNRules } from '../../slice/simSlice';
import { useAppDispatch } from '../../app/hooks';
import NoDataTableItem from '../AdminDashboard/NoDataTableItem';

interface ISNNRulesTableProps {
  snnRules: ISNNRule[];
  activeStatus: string;
  dispatchReducer: React.Dispatch<any>;
  readOnly: boolean;
}


const SNNRulesTable = ({ snnRules, activeStatus, dispatchReducer, readOnly }: ISNNRulesTableProps) => {
  let sortedSnnRules = [...snnRules];
  const dispatch = useAppDispatch();

  const dispatchSortAction = () => {
    dispatch(setSNNRules(sortedSnnRules));
  };

  const sortByLocale = (key: string) => {
    sortedSnnRules.sort((a, b) => { return a[key].localeCompare(b[key], undefined, { numeric: true }) });
    dispatchSortAction();
  }

  return <CTable className="snnTable" borderless hover>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Airline
          </div>
          <div>
            Flt no
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortByLocale("aircraftType")
        }}>
          A/C type
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortByLocale('registration');
        }}>
          Reg
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
          sortedSnnRules.sort((a, b) => { return a.dep[0].localeCompare(b.dep[0], undefined, { numeric: true }) });
          dispatchSortAction();
        }}>
          Ori
          <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snnHeader"} onClick={() => {
            sortedSnnRules.sort((a, b) => { return a.arr[0].localeCompare(b.arr[0], undefined, { numeric: true }) });
            dispatchSortAction();
        }}>
            Dest
            <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            ERA
          </div>
          <div>
            ALTN
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Eff Start UTC
          </div>
          <div>
            Eff End UTC
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Time rng
          </div>
          <div>
            Interval
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Waypoint
          </div>
          <div>
            Country Code
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Uploaded By
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"} onClick={() => {
        }}>
          <div>
            Version No
          </div>
        </CTableHeaderCell>
        <CTableHeaderCell scope="col" className={"CathaySansLt px-0 clickable snn snnHeader"} onClick={() => {
          sortByLocale("snn")
        }}>
            SNN
            <DownArrowIcon height={12} width={12} />
        </CTableHeaderCell>
        {!readOnly &&
          <CTableHeaderCell scope="col" className={"CathaySansLt px-0 snnHeader"}>Action</CTableHeaderCell>
        }
      </CTableRow>
    </CTableHead>
    <CTableBody>
        {!!snnRules && snnRules.length !==0 ?
            snnRules.map((snnRule, index) => {
              if((activeStatus === "Active" && snnRule.isActive === true) || (activeStatus === "Inactive" && snnRule.isActive === false) || (activeStatus === "All")){
                return <SNNRulesTableItem
                  key={`SNNRulesTableItem${index}`}
                  snnRule={snnRule}
                  dispatchReducer={dispatchReducer}
                  readOnly={readOnly}/>
              }
            })
            :
            (
              <NoDataTableItem colSpan={11} message={"There is no SNN Rules"} />
            )
        }
    </CTableBody>
  </CTable>
};

export default SNNRulesTable;
