interface PageContentProps {
    className?: string,
    content?: string,
    children?: React.ReactNode;
}

const PageContentWithoutPermission = ({ className = "mt-4 mb-4 text-center h2", content = "You have no permission. Please contact your administrator to grant access.", children }: PageContentProps) => {
    return (
        <div className={className}>
            {children ? children : <span>{content}</span>}
        </div >
    )
}

export default PageContentWithoutPermission;