const API_BASE_URL = window?.config?.REACT_APP_API_URL || process.env.REACT_APP_API_URL || "";
const API_BEKOL_URL = window?.config?.REACT_APP_BEKOL_API_URL || process.env.REACT_APP_BEKOL_API_URL || "";
const API_BASE = `${API_BASE_URL}/eff-api/v1`;
// deprecated, delete later
const BEKOL_API_BASE = `${API_BEKOL_URL}/eff-uo/internal/v1`;
const BEKOL_API_BASE_V2 = `${API_BEKOL_URL}/eff-uo/console/v1`;
const COMMON_API_BASE = `${API_BASE_URL}/eff-api/v1/common`;
const COMM_API_BASE = `${API_BASE_URL}/comm/1.0`;

const AUTH_API = {
    INIT: `${API_BASE_URL}/eff-api/auth/init`,
};

const DISPATCHER_API = {
  GET_PAST_HOUR_FLIGHT_PLAN: `${API_BASE}/dispatch/pastHourFlightPlan`,
  FLIGHT_STATUS: `${API_BASE}/dispatch/previousFlightStatus`,
  CLOSE_FLIGHT_LIST: `${API_BASE}/dispatch/getCloseFlightList`,
  CLOSE_FLIGHT_DETAIL: `${API_BASE}/dispatch/closeFlightDetail`,
  FIGHT_DETAIL: `${API_BASE}/dispatch/flightDetail`,
  POST_NOTAM_FILE: `${API_BASE}/notam/nsu/import`,
  GET_NOTE_LISTS: `${API_BASE}/dispatch/notes/list`,
  POST_UPDATE_NOTE: `${API_BASE}/dispatch/notes/update`,
  POST_CREATE_NOTE: `${API_BASE}/dispatch/notes/create`,
  POST_DELETE_NOTE: `${API_BASE}/dispatch/notes/delete`,
  GET_EDG_DETAIL: `${API_BASE}/dispatch/edg/detail`,
  GET_EDG_SUCCESS_RATE: `${API_BASE}/dispatch/edg/rate`,
};

const ADMIN_API = {
  ADJUST_FLIGHT_PLAN:  `${API_BASE}/admin/flightplan/adjust`
}

const FLIGHT_PLAN_CONFIG_API = {
  GET_CX_OE_NOTAM: `${API_BASE}/notam/oe/list`,
  GET_CX_NSU_NOTAM: `${API_BASE}/notam/nsu/list`,
  GET_CX_DEFECTS: `${API_BASE}/defect/list`,
  POST_CX_DEFECTS_SAVE:`${API_BASE}/defect/remark/save`
}

const AIRCRAFT_API = {
  GET_AIRCRAFTS: `${API_BASE}/aircraft/list`,
  POST_AIRCRAFT_CREATE: `${API_BASE}/aircraft/create`,
}

const SNN_API = {
  GET_SNN_RULES: `${API_BASE}/snn/list`,
  POST_SNN_RULE_CREATE: `${API_BASE}/snn/create`,
  POST_SNN_RULE_UPDATE: `${API_BASE}/snn/`,
  DELETE_SNN_RULE: `${API_BASE}/snn/`,
}

const APPENDIX_API = {
  GET_APPENDIX_RULES: `${API_BASE}/appendix/list`,
  GET_APPENDIX_FILES: `${API_BASE}/appendix/file/list`,
  POST_APPENDIX_RULE_CREATE: `${API_BASE}/appendix/create`,
  POST_APPENDIX_RULE_UPDATE: `${API_BASE}/appendix/`,
  DELETE_APPENDIX_RULE: `${API_BASE}/appendix/`,
  POST_UPLOAD_APPENDIX_FILE: `${API_BASE}/appendix/file/upload`,
  DELETE_APPENDIX_FILE: `${API_BASE}/appendix/file`
}

const PORT_API = {
  GET_PORT: `${API_BASE}/port/search`,
  GET_PORT_LIST: `${API_BASE}/port/list`,
  POST_PORT_ADD: `${API_BASE}/port/add`,
  POST_PORT_ADD_ELEMENT: `${API_BASE}/port/addElement`,
  POST_PORT_SAVE_ELEMENT: `${API_BASE}/port/saveElement`,
  POST_PORT_DELETE_ELEMENT: `${API_BASE}/port/deleteElement`,
  POST_PORT_RE_ORDER_TAB: `${API_BASE}/port/reOrderElement`,
  POST_PORT_UPLOAD_FILE: `${API_BASE}/port/uploadFile`,
  POST_PORT_DELETE_FILE: `${API_BASE}/port/deleteFile`,
  POST_PORT_DOWNLOAD_FILE: `${API_BASE}/port/downloadFile`,
}

const PORT_RUNWAY_API = {
  GET_RUNWAY_LIST: `${API_BASE}/runway/list`,
  POST_RUNWAY_SAVE: `${API_BASE}/runway/save`,
  GET_RUNWAY_PDF: `${API_BASE}/runway/pdf`,
}

const DATA_API = {
  GET_COLLECTIONS: `${API_BASE}/data/col/list`,
  GET_DOCUMENTS: `${API_BASE}/data/col/document/list`,
  UPDATE_DOCUMENT: `${API_BASE}/data/col/document/update`,
  DELETE_DOCUMENTS: `${API_BASE}/data/col/document/delete`,
}

const SIM_API = {
  GET_SIM_PLANS: `${API_BASE}/sim/list`,
  GET_SIM_PLAN_TEMPLATES: `${API_BASE}/sim/list/templates`,
  GET_SIM_AIRCRAFT_TYPES: `${API_BASE}/sim/list/aircraftTypes`,
  POST_SIM_PLAN_UPDATE: `${API_BASE}/sim/update`,
  POST_SIM_DIGITAL_REFUELING: `${API_BASE}/sim/digitalRefuel`,
  POST_SIM_TEMPLATES: `${API_BASE}/sim/import/eff`,
  DELETE_SIM_TEMPLATES: `${API_BASE}/sim/delete/template`,
  UPDATE_SIM_TEMPLATES: `${API_BASE}/sim/update/template`,
};

const BEKOL_API = {
  GET_PAST_HOUR_FLIGHT_PLAN: `${BEKOL_API_BASE_V2}/past-24hour-flightplan`,
  GET_SNN_RULES: `${BEKOL_API_BASE_V2}/list-snn-rules`,
  GET_DEFECT: `${BEKOL_API_BASE_V2}/list-defects`,
  GET_NSU_NOTAM: `${BEKOL_API_BASE_V2}/list-nsu-notam`,
  GET_OE_NOTAM: `${BEKOL_API_BASE_V2}/list-oe-notam`,
  GET_APPENDIX_RULE: `${BEKOL_API_BASE_V2}/list-appendix-rule`,
  GET_CREWLIST: `${BEKOL_API_BASE_V2}/crew/list`,
  CLOSE_FLIGHT_LIST: `${BEKOL_API_BASE_V2}/getCloseFlightList`,
  CLOSE_FLIGHT_DETAIL: `${BEKOL_API_BASE_V2}/closeFlightDetail`,
  FIGHT_DETAIL: `${BEKOL_API_BASE_V2}/flightDetail`,
  UPDATE_FUEL_ADVISORY: `${BEKOL_API_BASE_V2}/fuel/advisory/update`,
  GET_FUEL_ADVISORY: `${BEKOL_API_BASE_V2}/fuel/advisory/list`,
  POST_NOTAM_FILE: `${BEKOL_API_BASE_V2}/notam/nsu/import`,
  GET_SNN_LIST: `${BEKOL_API_BASE_V2}/snn/list`,
  POST_SNN_RULE_CREATE: `${BEKOL_API_BASE_V2}/snn/create`,
  POST_SNN_RULE_UPDATE: `${BEKOL_API_BASE_V2}/snn/`,
  DELETE_SNN_RULE: `${BEKOL_API_BASE_V2}/snn/`,
  GET_APPENDIX_RULES: `${BEKOL_API_BASE_V2}/appendix/list`,
  GET_APPENDIX_FILES: `${BEKOL_API_BASE_V2}/appendix/file/list`,
  POST_APPENDIX_RULE_CREATE: `${BEKOL_API_BASE_V2}/appendix/create`,
  POST_APPENDIX_RULE_UPDATE: `${BEKOL_API_BASE_V2}/appendix/`,
  DELETE_APPENDIX_RULE: `${BEKOL_API_BASE_V2}/appendix/`,
  POST_UPLOAD_APPENDIX_FILE: `${BEKOL_API_BASE_V2}/appendix/file/upload`,
  DELETE_APPENDIX_FILE: `${BEKOL_API_BASE_V2}/appendix/file`,
  GET_EMAILLIST_DATA: `${BEKOL_API_BASE_V2}/carreport/email/list`,
  POST_EMAIL_CREATE: `${BEKOL_API_BASE_V2}/carreport/email/create`,
  POST_EMAIL_UPDATE: `${BEKOL_API_BASE_V2}/carreport/email/update`,
  DELETE_EMAIL_DELETE: `${BEKOL_API_BASE_V2}/carreport/email/delete`,
  GET_SIDSTAR_DATA: `${BEKOL_API_BASE_V2}/sidstar/list`,
  POST_SIDSTAR_UPLOAD_FILE: `${BEKOL_API_BASE_V2}/sidstar/upload`,
  POST_SIDSTAR_DOWNLOAD_FILE: `${BEKOL_API_BASE_V2}/sidstar/download`,
  GET_RECORD_COUNT: `${BEKOL_API_BASE_V2}/sidstar/count`,
};

const FEEDBACK_API = {
  GET_FEEDBACK_TAGS: `${API_BASE}/deparr/feedback/tags/list`,
  GET_FEEDBACK_RUNWAYS: `${API_BASE}/deparr/feedback/runways/list`,
  GET_FEEDBACK_LISTS: `${API_BASE}/deparr/feedback/list`,
  POST_UPDATE_FEEDBACK: `${API_BASE}/deparr/feedback/update`
};

const TAGGING_API = {
  GET_TAGGING_LIST: `${API_BASE}/deparr/tagging/list`,
  POST_UPDATE_TAGGING: `${API_BASE}/deparr/tagging/update`
};

const COMM_API = {
  GET_RESOURCE: `${COMM_API_BASE}/message/resource`
};

const COMMON_API = {
  GET_AIRCRAFT_TYPE: `${COMMON_API_BASE}/aircraft/type/list`
};

const GOOGLE_MAP_API = {
  REDIRECT: "https://www.google.com/maps/search/?api=1&query=:latlng"
};

const RESOURCE_API = `${API_BASE}/resources`;

const SUPPORT_API = {
  GET_SUPPORT_DATA_CFCCS: `${API_BASE}/support/cfccs/list`,
  POST_UPDATE_SUPPORT_DATA_CFCCS: `${API_BASE}/support/cfccs/update`
};

const SIDSTAR_API = {
  GET_SIDSTAR_DATA: `${API_BASE}/sidstar/list`,
  POST_SIDSTAR_UPLOAD_FILE: `${API_BASE}/sidstar/upload`,
  POST_SIDSTAR_DOWNLOAD_FILE: `${API_BASE}/sidstar/download`,
  GET_RECORD_COUNT: `${API_BASE}/sidstar/count`,
}


const AXIOS_ERR_CODE_TIMEOUT = "ECONNABORTED";

const HTTP_REQUEST_TYPE = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
};

export {
  API_BASE_URL,
  AXIOS_ERR_CODE_TIMEOUT,
  HTTP_REQUEST_TYPE,
  AUTH_API,
  DISPATCHER_API,
  ADMIN_API,
  FLIGHT_PLAN_CONFIG_API,
  AIRCRAFT_API,
  SNN_API,
  APPENDIX_API,
  PORT_API,
  DATA_API,
  SIM_API,
  BEKOL_API,
  GOOGLE_MAP_API,
  RESOURCE_API,
  COMM_API,
  COMMON_API,
  SUPPORT_API,
  PORT_RUNWAY_API,
  FEEDBACK_API,
  TAGGING_API,
  SIDSTAR_API
};
