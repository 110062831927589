import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import GenericTable from '../../components/AdminDashboard/GenericTable';
import CXDropDown from '../../components/CXForm/CXDropDown';
import { GENERIC_TABLE_TYPES } from '../../constants/genericTableConstant';
import { checkPagePermission } from '../../constants/simConstant';
import { getUODefects } from '../../services/bekolDispatchAPI';
import { resetDefects, selectDispatcher, setDefects } from '../../slice/dispatcherSlice';
import { selectUser } from '../../slice/userSlice';

/**
 * Application home page component
 * @returns {JSX}
 */


const UODefectPage = () => {
  const dispatch = useAppDispatch();
  const { defect } = useAppSelector(selectDispatcher);
  const { access } = useAppSelector(selectUser);
  const { isAdmin, isUODispatch } = access as any;
  const [defectType, setDefectType] = useState<string>("P");
  const defectTypeOptions = [
    {
      text: "ALL",
      value: "ALL"
    }, 
    {
      text: "P",
      value: "P"
    }
  ] 
  const defectColName: Array<string> = [
    "Aircraft",
    "ADD#",
    "ADD Title",
    "ADD Detail",
    "MEL ID",
    "Expiry Date UTC",
    "Created / Updated Time UTC",
    "Deleted",
  ];

  useEffect(() => {
    dispatch(resetDefects());
    const getDefect = async () => {
      let response;
      if (checkPagePermission([isAdmin, isUODispatch])) {
        response = await getUODefects(defectType);
      }

      if (!!response) {
        dispatch(setDefects(response));
      }
    }
    getDefect();
  }, [dispatch, isAdmin, isUODispatch, defectType])

  return (
    <>
      <div className='p-0 m-0 d-flex align-items-center'>
        <div className={"pageHeading CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>Defects</div>
      </div>
      <div className='uo-defect'>
        <CXDropDown 
          value={defectType}
          placeholder={"Defect Type"}
          onChange={setDefectType}
          options={defectTypeOptions}
          zIndex={1319}/>
      </div>
      <div className={"section"}>
        <GenericTable 
          type={GENERIC_TABLE_TYPES.defect} 
          fieldNames={defectColName} 
          data={defect || []} 
        />
      </div>
    </>
  );
};

export default UODefectPage;