import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface AuthSlice {
    isLogin: boolean;
    userName: string;
    userId: string;
    clientId: string;
    accessToken?: string;
}

const initialState: AuthSlice = {
    isLogin: false,
    userName: "",
    userId: "",
    clientId: "",
    accessToken: "",
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, { payload }) => {
            const { userName, userId, clientId, accessToken} = payload || {};
            state.userId = userId;
            state.userName = userName;
            state.clientId = clientId;
            state.accessToken = accessToken;
            state.isLogin = true;
        },
        logout: (state) => {
            state.isLogin = false;
            state.userName = "";
            state.userId = "";
            state.clientId = "";
            state.accessToken = "";
        },
    },
});

const selectAuth = (state: RootState) => state.auth;
const { login, logout } = authSlice.actions;

export { authSlice, selectAuth, login, logout };
export type { AuthSlice };

export default authSlice.reducer;
