import * as React from 'react';
import _ from 'lodash';
import {CTableRow, CTableDataCell} from '@coreui/react';
import {ChatIcon, EyeIcon, EyeCloseIcon} from '../../assets/images';
import {IFeedbackTagVisible, IFeedbackVisible} from "../../interfaces/crewfeedback/feedback";

interface IFeedbackListProps {
  index: number;
  feedback: {
    _id: string,
    ufi: string,
    std: string,
    galacxyId: string,
    aircraftType: string,
    icao: string,
    type: string,
    text: string,
    tags: string[],
    runways: string[],
    hidden: boolean,
    createdAt: string,
    updatedAt: string,
    likeCount: number,
    likeUfi: string[],
    saveCount: number,
    saveUfi: string[],
    remarks: string
  };
  hideFeedback: Function;
  addRemarks: Function;

}

const formatDate = (dateStr) => {
  const date = new Date(dateStr)
  const mon = date.toDateString().split(' ')[1]
  return `${date.getDate()}${mon}${date.getFullYear()}`
}

export const FeedbackListItem = ({index, feedback, hideFeedback, addRemarks}: IFeedbackListProps) => {
  return <CTableRow style={{background: feedback.hidden ? "#F2F2F3" : "#FFFFFF"}}>
    <CTableDataCell style={{background: "transparent", width: "60px"}} className={"RobotoMonoRg"}>
      <div>{feedback.icao || "-"}</div>
      <div>{feedback.type.toUpperCase() || "-"}</div>
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "100px"}} className={"RobotoMonoRg"}>
      <div>{feedback.ufi.substring(0, 7) || "-"}</div>
      <div>{feedback.aircraftType || "-"}</div>
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "160px"}} className={"RobotoMonoRg"}>
      {formatDate(feedback.std) || "-"}
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "700px"}} className={"RobotoMonoRg"}>
      {feedback.text || "-"}
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "70px"}} className={"RobotoMonoRg"}>
      <div className="cx-tooltip">
        <span className="text">{feedback.likeCount || 0}</span>
        {(feedback.likeCount > 0) &&
          <span className="tooltiptext" style={{marginLeft: "-125px"}}>{feedback.likeUfi.join('\n')}</span>}
      </div>
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "80px"}} className={"RobotoMonoRg"}>
      <div className="cx-tooltip">
        <span className="text">{feedback.saveCount || 0}</span>
        {(feedback.saveCount > 0) &&
          <span className="tooltiptext" style={{marginLeft: "-125px"}}>{feedback.saveUfi.join('\n')}</span>}
      </div>
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "300px"}} className={"RobotoMonoRg"}>
      <div style={{display: "flex", flexWrap: "wrap", flexShrink: "0"}}>
        {
          feedback.tags.map((tag) =>
            <span style={{
              color: '#FFFFFF',
              background: '#4C4C4C',
              borderRadius: '5px',
              margin: '2px',
              padding: '4px',
              float: "left"
            }}>{tag || ''}</span>
          )
        }
      </div>

      {feedback.runways && (feedback.runways.length > 0) &&
        <div style={{display: "flex", flexWrap: "wrap", flexShrink: "0"}}>
          {
            feedback.runways.map((runway) =>
              <span style={{
                color: '#4C4C4C',
                background: '#DBDBDB',
                borderRadius: '5px',
                margin: '2px',
                padding: '4px',
                float: "left"
              }}>{runway.replace(/ARR |DEP /gi, "") || ""}</span>
            )
          }
        </div>}
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent", width: "100px"}}
                    className={"RobotoMonoRg"}>{feedback.galacxyId || "-"}</CTableDataCell>
    <CTableDataCell style={{background: "transparent"}}
                    className={"RobotoMonoRg"}>{feedback.remarks || "-"}</CTableDataCell>
    <CTableDataCell style={{background: "transparent"}} align='middle'>
      <div className={"d-flex align-items-center p-0"}>
        <div className={"clickable"} style={{marginLeft: "4px", background: "transparent"}}>
          <ChatIcon style={{height: "3rem", width: '3rem'}} onClick={() => addRemarks(index)}/>
        </div>
      </div>
    </CTableDataCell>
    <CTableDataCell style={{background: "transparent"}} align='middle'>
      <div className={"d-flex align-items-center p-0"}>
        <div className={"clickable"} style={{marginLeft: "-20px", background: "transparent"}}>
          {feedback.hidden && <EyeIcon style={{height: "3rem", width: '3rem'}} onClick={() => hideFeedback(index)}/>}
          {!feedback.hidden &&
            <EyeCloseIcon style={{height: "3rem", width: '3rem'}} onClick={() => hideFeedback(index)}/>}
        </div>
      </div>
    </CTableDataCell>
  </CTableRow>
};
const cellStyle = {
  color: "#000000",
  fontFamily: "Cathay Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "0.3px",
  flex: 1
}
const TruncatedText = (text: string, maxWords: number) => {
  if (_.isEmpty(text)) return "-";
  const words = text.split(' ');
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  } else {
    return text;
  }
}
const TruncatedTag = (tags: string[], maxTags: number) => {
  if (_.isEmpty(tags)) return "";
  let handledTags = [...tags];
  if (tags.length > maxTags) {
    handledTags = _.slice(tags, 0, maxTags);
    handledTags.push('...')
  }
  return (handledTags.map((tag) =>
    <span style={{
      color: '#FFFFFF',
      background: '#4C4C4C',
      borderRadius: '5px',
      margin: '2px',
      padding: '4px',
      float: "left"
    }}>{tag || ''}</span>
  ))
}
export const FeedbackVisibilityDialogItem = (feedback: IFeedbackVisible) => {
  return <CTableRow
    style={{display: "flex", flexDirection: "row", marginLeft: "36px", marginRight: "36px", height: "10%"}}>
    <CTableDataCell style={cellStyle} className={"RobotoMonoRg"}>
      <div>{feedback.port || "-"}</div>
    </CTableDataCell>
    <CTableDataCell style={cellStyle} className={"RobotoMonoRg"}>
      <div>{feedback.tab.toUpperCase() || "-"}</div>
    </CTableDataCell>
    <CTableDataCell style={cellStyle} className={"RobotoMonoRg"}>
      <div>{feedback.ufi.substring(0, 7) || "-"}</div>
      <div>{feedback.aircraftType || "-"}</div>
    </CTableDataCell>
    <CTableDataCell style={cellStyle} className={"RobotoMonoRg"}>
      {formatDate(feedback.flightDate) || "-"}
    </CTableDataCell>
    <CTableDataCell style={{...cellStyle, flex: 2, height: "100%", overflow: "auto"}} className={"RobotoMonoRg"}>
      {TruncatedText(feedback.content, 15)}
    </CTableDataCell>
    <CTableDataCell style={{...cellStyle, flex: 2}} className={"RobotoMonoRg"}>
      <div style={{display: "flex", flexWrap: "wrap", flexShrink: "0"}}>
        {TruncatedTag(feedback.tags, 15)}
      </div>

      {feedback.runways && (feedback.runways.length > 0) &&
        <div style={{display: "flex", flexWrap: "wrap", flexShrink: "0"}}>
          {
            feedback.runways.map((runway) =>
              <span style={{
                color: '#4C4C4C',
                background: '#DBDBDB',
                borderRadius: '5px',
                margin: '2px',
                padding: '4px',
                float: "left"
              }}>{runway.replace(/ARR |DEP /gi, "") || ""}</span>
            )
          }
        </div>}
    </CTableDataCell>
    <CTableDataCell style={{...cellStyle, flex: 2}}
                    className={"RobotoMonoRg"}>{TruncatedText(feedback.remarks, 15)}</CTableDataCell>
  </CTableRow>
}

export const FeedbackTagVisibilityDialogItem = (feedbackTag: IFeedbackTagVisible) => {
  return <CTableRow style={{display: "flex", flexDirection: "row", marginLeft: "36px", marginRight: "36px", height: "10%"}}>
    <CTableDataCell style={{...cellStyle, flex: 1}} className={"RobotoMonoRg"}>
      <div style={{display: "flex", flexWrap: "wrap", flexShrink: "0"}}>
        {
          <span style={{
            color: '#FFFFFF',
            background: '#4C4C4C',
            borderRadius: '5px',
            margin: '2px',
            padding: '4px',
            float: "left"
          }}>{feedbackTag.tag || ''}</span>
        }
      </div>
    </CTableDataCell>
    <CTableDataCell style={{...cellStyle, flex: 1}} className={"RobotoMonoRg"}>
      <div>{feedbackTag.port || "-"}</div>
    </CTableDataCell>
    <CTableDataCell style={{...cellStyle, flex: 1}} className={"RobotoMonoRg"}>
      <div>{feedbackTag.tab.toUpperCase() || "-"}</div>
    </CTableDataCell>
  </CTableRow>
}


