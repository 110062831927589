import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CCard, CCardBody, CCardSubtitle, CCardText, CCardTitle, CButton } from '@coreui/react-pro';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import { selectData, setCollections } from '../../slice/dataSlice';
import { getCollections } from '../../services/dataAPI';
import { FLIGHT_PLAN_CONFIG_PATHS } from '../../constants/path';
import PageHeaderTitle from '../../components/PageHeaderTitle';
import PageContentWithoutPermission from '../../components/PageContentWithoutPermission';

const DataPage = () => {
  const dispatch = useAppDispatch();
  const { collections } = useAppSelector(selectData);
  const { access } = useAppSelector(selectUser);
  const { isAdmin } = access as any;
  const hasAccess = isAdmin || false;
  const readOnly = false; // pending to do

  useEffect(() => {
    hasAccess && onClickSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSearch = () => {
    getCollections().then((res) => {
      if (res?.status === 500) {
        console.log('getCollections failed, res=', res);
      } else {
        dispatch(setCollections(res || []));
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    hasAccess ?
      <>
        <PageHeaderTitle headerTitle={'Collections'} />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {collections && collections.length > 0 && collections.map(collection =>
            <CCard key={`key_${collection.name}`} style={{ width: '18rem', margin: "20px" }}>
              <CCardBody>
                <CCardTitle><Link to={`${FLIGHT_PLAN_CONFIG_PATHS.dataDetail}?colName=${encodeURIComponent(collection.name)}`}>{collection.name}</Link></CCardTitle>
                <CCardSubtitle className="mb-2 text-medium-emphasis">{`${collection.size} Items`}</CCardSubtitle>
                <CCardText>
                </CCardText>
                {!readOnly && <CButton href={`${FLIGHT_PLAN_CONFIG_PATHS.dataDetail}?colName=${encodeURIComponent(collection.name)}&createNewFlag=true`}>Add document</CButton>}
              </CCardBody>
            </CCard>
          )}
        </div>
      </>
      : <PageContentWithoutPermission />
  );
};

export default DataPage;
