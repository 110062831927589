import { msalConfigType } from "../interfaces/configurations";
import { 
  PublicClientApplication,
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionType 
} from "@azure/msal-browser";
import jwt_decode from "jwt-decode";

import { login, logout } from "../slice/authSlice";
import { clientId, initClientApi } from "../services/api";
import { store } from "../app/store";
import { clearUser } from "../slice/userSlice";

const CLIENT_ID: string = window?.config?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID || "";

export const msalConfig: msalConfigType = {
  auth: {
    clientId: CLIENT_ID,
    authority: window?.config?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY || "",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile", "email", "offline_access", `api://${CLIENT_ID}/user_impersonation`],
  forceRefresh: true,
 };

export const effEventCallBack = async (message: EventMessage) => {
  // console.debug("msalEvent:", message);

  if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    console.debug(message.interactionType);
    if (message.interactionType === InteractionType.Silent) {
      if (message.error) {
        console.error(message.error);
      }
      const payload = message.payload as AuthenticationResult;
      if (payload.accessToken && payload.idTokenClaims) {
        const { accessToken, account } = payload;
        const localAccountId = account?.localAccountId || "";
        const idTokenClaims = account?.idTokenClaims as any;
        let username: string = "";
        if(idTokenClaims?.sAMAccountName){
          username = idTokenClaims?.sAMAccountName;
        } else {
          username = idTokenClaims?.preferred_username != "" ? idTokenClaims?.preferred_username.split("@")[0] : username;
        }
        displayTokenExp(accessToken);
        store.dispatch(login({ accessToken, clientId, userId: localAccountId, userName: username }));
        await initClientApi(accessToken);
      }
    }
  }
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    if (message.error) {
      console.error(message.error);
    } else {
      const payload = message.payload as AuthenticationResult;
      if (payload.accessToken && payload.idTokenClaims) {
        const { accessToken, account } = payload;
        const localAccountId = account?.localAccountId || "";
        const idTokenClaims = account?.idTokenClaims as any;
        let username: string = "";
        if(idTokenClaims?.sAMAccountName){
          username = idTokenClaims?.sAMAccountName;
        } else {
          username = idTokenClaims?.preferred_username != "" ? idTokenClaims?.preferred_username.split("@")[0] : username;
        }
        displayTokenExp(accessToken);
        store.dispatch(login({ accessToken, clientId, userId: localAccountId, userName: username }));
        await initClientApi(accessToken);
      }
    }
  }
};

export function getTokenExpireAt(accessToken: string) {
  const jsonToken: { exp?: number } = jwt_decode(accessToken);
  if (jsonToken && jsonToken.exp) {
    const expireAt = jsonToken.exp;
    return expireAt;
  }
  return null;
}

export function displayTokenExp(token: string) {
  const jsonToken: { exp?: number; ver?: string } = jwt_decode(token);
  if (jsonToken && jsonToken.exp) {
    if (jsonToken.ver && jsonToken.ver !== "2.0") {
      console.warn("Token is not v2");
    }
    const expireAt = jsonToken.exp;
    console.debug(
      `token version: ${jsonToken.ver} will expire at [${expireAt}] ${new Date(
        expireAt * 1000
      )}`
    );
  }
}

export async function getNewAccessToken(): Promise<string> {
  console.debug("Get New Access Token");
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    store.dispatch(logout);
    store.dispatch(clearUser);
    logoutRedirect();
    throw new Error("User not login");
  }

  const authResult = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .catch((error) => {
      console.error("acquireTokenSilentError", error);
      alert("The session has expired.\nPlease login again.");
      store.dispatch(logout);
      store.dispatch(clearUser);
      logoutRedirect();
      throw new Error("Failed to refresh accessToken");
    });
  return authResult.accessToken;
}


export async function getAccessToken() {
  const state = store.getState();
  const isLogin = state.auth.isLogin;
  if (isLogin) {
    const accessToken = state.auth.accessToken;
    if (accessToken) {
      const expireAt = getTokenExpireAt(accessToken);
      if (expireAt) {
        const expireBuffer = 30;
        const expirationInMs = (expireAt - expireBuffer) * 1000;
        if (expirationInMs >= Date.now()) {
          return accessToken;
        } else {
          console.debug(
            `${Date.now()} vs ${expirationInMs} ${
              expirationInMs - Date.now()
            } - token is about to expire in ${expireBuffer} seconds`
          );
        }
      }
    }
  }

  const newAccessToken = await getNewAccessToken();
  return newAccessToken;
}

export const logoutRedirect = () => {
  const accounts = msalInstance.getAllAccounts();
  msalInstance.logoutRedirect({
    account: accounts[0],
    postLogoutRedirectUri: "/",
  });
}

export const loginRedirect = () => {
  msalInstance.loginRedirect(loginRequest);
};

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback(effEventCallBack);