import { postAppendixFile} from "../services/appendixAPI";

const MILESTONE_TYPES = {
  release: "release",
  loadsheet: "loadsheet",
  refuelingInProgress: "refuelingInProgress",
  refuelingReceipt: "refuelingReceipt",
};

const DIGITAL_REFUELING_TYPES = {
  ebitsMilestone: "ebitsMilestone",
  ebitsSummary: "ebitsSummary",
};

const SNN_SEARCH_FIELDS = {
  airline: "airline",
  flightNo: "flightNo",
  aircraftType: "aircraftType",
  registration: "registration",
  dep: "dep",
  arr: "arr",
  altn: "altn",
  era: "era",
  snn: "snn"
}

const SNN_REQUIRED_FIELDS = [
  "airline",
  "flightNo",
  "aircraftType",
  "registration",
  "dep",
  "arr",
  "startAt",
  "endAt",
  "timeRange",
  "waypoint",
  "countryICAOCode",
  "snn"
]

const APPENDIX_SEARCH_FIELDS = {
  aircraftType: "aircraftType",
  registration: "registration",
  files: "files",
  expiry: "expiry",
  review: "review",
  airline: "airline",
  dep: "dep",
  arr: "arr",
  altn: "altn",
  era: "era"
}

const APPENDIX_REQUIRED_FIELDS = [
  "airline",
  "flightNo",
  "aircraftType",
  "registration",
  "dep",
  "arr",
  "expiry",
  "review",
  "files"
]

const WEEKDAYS_MULTISELECT = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
}

const WEEKDAYS_MULTISELECT_VALUE = [
    {
      value: 1,
      text: 'Monday',
    },
    {
      value: 2,
      text: 'Tuesday',
    },
    {
      value: 3,
      text: 'Wednesday',
    },
    {
      value: 4,
      text: 'Thursday',
    },
    {
      value: 5,
      text: 'Friday',
    },
    {
      value: 6,
      text: 'Saturday',
    },
    {
      value: 7,
      text: 'Sunday',
    }
  ]

/**
 * Splits a file name into its base name and extension.
 * @param fileName - The name of the file.
 * @returns An array containing the base name and extension of the file.
 */
const getFileNameParts = (fileName: string) => {
  const dotIndex = fileName.lastIndexOf('.');
  // If dotIndex equals -1, it means the file has no extension.
  if (dotIndex === -1) {
    return [fileName, ''];
  } else {
    return [fileName.slice(0, dotIndex), fileName.slice(dotIndex + 1)];
  }
};

const duplicateFiles = (fileList: any, fileName: string) => {

  let [fileNameWithoutExtension, fileExtension] = getFileNameParts(fileName);
  let regex = new RegExp(`^${fileNameWithoutExtension}(\\(\\d+\\))?\\.?${fileExtension || ''}$`);
  let filteredArray = fileList.filter((item: any) => item.pdfName.match(regex));
  return filteredArray
}

// const uploadFile = (e: any, fileList: any) => {
//   let file = e.target.files?.[0];
//   let fileName = file.name;

//   let count = duplicateFiles(fileList, fileName).length;
//   if (count <= 0) return file;

//   let [fileNameWithoutExtension, fileExtension] = getFileNameParts(fileName);
//   if (fileExtension == '') {
//     fileName = `${fileName}(${count})`
//   } else {
//     fileName = `${fileNameWithoutExtension}(${count}).${fileExtension}`
//   }
//   file = new File([file], fileName, { type: file.type });
//   return file;
// }

const uploadAppendixFile = async (e, userName,fileList, isHKE = false) => {
  if (!!e.target.files?.[0] && userName) {
    let bodyFormData = new FormData();
    const file = e.target.files?.[0];
    bodyFormData.append("file", file);
    bodyFormData.append("createdBy", userName);
    // console.log(file,bodyFormData);
   return postAppendixFile(bodyFormData, isHKE).then(res => {
      if (res.status === 500) {
        throw res;
      }
      if (res.status === "ok") {
        return res;
        // dispatch(pushToast({ type: "success", message: "New SIM template added" }));
      }
      return res;

      // getTemplates();
    }).catch(err => {
      if (err.status === 500) {
        // dispatch(pushToast({ type: "error", message: "Import SIM template failed" }));
      } else {
        console.log(err);
      }
    })
  }

  // fix: avoid select File not firing
  e.target.value = "";
};

// const uploadHKEAppendixFile = async (e, userName,fileList) => {
//   if (!!e.target.files?.[0] && userName) {
//     let bodyFormData = new FormData();
//     const file = uploadFile(e, fileList)
//     bodyFormData.append("eff-file", file);
//     bodyFormData.append("created-by", userName);
//     console.log(file, bodyFormData)
//     return postHKEAppendixFile(bodyFormData).then(res => {
//       if (res.status === 500) {
//         throw res;
//       }
//       if (res.status === "ok") {
//         return res;
//         // dispatch(pushToast({ type: "success", message: "New SIM template added" }));
//       }
//       return res;

//       // getTemplates();
//     }).catch(err => {
//       if (err.status === 500) {
//         // dispatch(pushToast({ type: "error", message: "Import SIM template failed" }));
//       } else {
//         console.log(err);
//       }
//     })
//   }

//   // fix: avoid select File not firing
//   e.target.value = "";
// };

const checkPagePermission = (roleList:any[]) => {
  return typeof roleList.find(role => typeof role != "undefined") != "undefined";
}

const isReadOnly = (roleList:any[]) => {

  const filteredList = roleList.filter(role => typeof role != "undefined");
  if(filteredList.length > 0){
    if (filteredList.includes(true)){
      return false;
    }

    return true;
  }

  return undefined;
}

export {
  MILESTONE_TYPES,
  DIGITAL_REFUELING_TYPES,
  SNN_SEARCH_FIELDS,
  APPENDIX_SEARCH_FIELDS,
  WEEKDAYS_MULTISELECT,
  uploadAppendixFile,
  // uploadHKEAppendixFile,
  SNN_REQUIRED_FIELDS,
  APPENDIX_REQUIRED_FIELDS,
  WEEKDAYS_MULTISELECT_VALUE,
  checkPagePermission,
  isReadOnly
};
