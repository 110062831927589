import * as React from 'react';
import moment from 'moment';
import {CTableRow, CTableDataCell, CCol} from '@coreui/react';
import {EditIcon} from "../../assets/images";

const EditButton = ({data, dispatcher}) => {
    return <div className={`"snnTableRow clickable"`} style={{marginLeft: "4px"}}>
        <EditIcon height={40} width={40} onClick={() => {
            dispatcher({ type: "EDIT", updatedConfigItem: data });
        }} />
    </div>
};

const FuelAdvConfigTableItem = ({fuelAdv, dispatchReducer}) => {
    return <CTableRow>
        <CTableDataCell className={"RobotoMonoRg"}>{fuelAdv.icao || "-"}</CTableDataCell>
        <CTableDataCell
            className={"RobotoMonoRg"}>{moment.utc(fuelAdv.lastUpdated).format("YYYY-MM-DD HH:mm") || "-"}</CTableDataCell>
        <CTableDataCell className={"RobotoMonoRg"}>{fuelAdv.galacxyId || "-"}</CTableDataCell>
        <CTableDataCell className={"RobotoMonoRg"}
                        style={{whiteSpace: "pre-line"}}>{fuelAdv.emails.join("\n ") || "-"}</CTableDataCell>
        <CTableDataCell className={"RobotoMonoRg"}>{fuelAdv.active ? "Yes" : "No"}</CTableDataCell>
        <CTableDataCell><EditButton
            dispatcher={dispatchReducer}
            data={fuelAdv}
        /></CTableDataCell>
    </CTableRow>
};

export default FuelAdvConfigTableItem;
