import React, {useState} from 'react';
import {CTimePicker} from '@coreui/react-pro';
import './style/style.scss';
import moment from 'moment';
import {ClockIcon} from '../../assets/images';

interface ICXTimePickerProps {
    timepicker?: boolean;
    disabled?: boolean;
    zIndex?: number;
    onChange: Function;
    placeholder: string;
    value: string;
    onChangeField?: Function;
    field?: string;
    state?: any;
    effectiveStart?: Date;
    effectiveEnd?: Date;
}

const CXTimePicker = ({
                          disabled = false,
                          timepicker = false,
                          zIndex,
                          onChange,
                          placeholder,
                          value,
                          onChangeField,
                          field,
                          state
                      }: ICXTimePickerProps) => {
    const [focus, setFocus] = useState(false);
    const [timeValue, setTimeValue] = useState<Date>(new Date());

    /*
      ----------------------------- Sample component ------------------------------
      const [displayDate, setDisplayDate] = useState([pass in UTC date]);
      <CXDatePicker placeholder={"STD"} value={displayDate} onChange={setDisplayDate} timepicker={true} />
      ----------------------------- Sample component ------------------------------
    */

  /*
    ----------------------------- Save for future debugging -----------------------------
    console.log(` 1. first enter ${value} `);
    console.log(` 2. CDatePicker date ${!!value ? `${moment.utc(new Date(value)).format("YYYY-MM-DDTHH:mm:ss")}Z` : `${moment.utc().format("YYYY-MM-DDTHH:mm:ss")}Z`} `);
    ----------------------------- Save for future debugging -----------------------------
  */

    const toggleFocus = () => {
        if (!disabled) {
            setFocus(!focus);
        }
    };

  const select = () => {
    // return utc time to onChange function
    // casper version
    // let confirmTime = timeValue;
    // confirmTime.setHours(confirmTime.getHours() + 8)
    // const utcTime = confirmTime.toISOString()
    const utcTimeStr = moment(timeValue).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    if (onChangeField) {
      onChangeField(utcTimeStr, field, state, onChange);
    }
    setFocus(false);
  };

    return (
        <div className={"cx-datepicker"} style={{zIndex: !!zIndex ? zIndex : 1038}}>
            <div className={"cx-form-control"}>
                <CTimePicker
                    className={`cx-datepicker-body ${disabled ? "cx-disabled" : ""}`}
                    locale="en-GB"
                    indicator={false}
                    time={timeValue}
                    placeholder={placeholder}
                    onTimeChange={(timeString, localeTimeString, date) => setTimeValue(date ? date : new Date())}
                    onConfirm={() => select()}
                    disabled={disabled}/>
                <ClockIcon className={`cx-calender-icon dropdown-toggle`} height={16} width={16}/>
                {(focus || !!value) &&
                    <div className={"focused-label cx-datepicker-labels"} onClick={toggleFocus}>{placeholder}</div>}
                {(focus || !!value) &&
                    <div className={"selected-date cx-datepicker-labels"}>{moment.utc(value)?.format("HH:mm")}</div>}
                {(!focus && !value) &&
                    <div className={`not-focused-label cx-datepicker-labels`} onClick={toggleFocus}>{placeholder}</div>}
            </div>
        </div>
    );
};

export default CXTimePicker;
