import React, { useEffect, useState } from 'react';
import moment from 'moment';

import './style/FlightStatusTable.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getFlightStatus } from '../../../services/dispatchAPI';
import { selectDispatcher, setFlightstatus } from '../../../slice/dispatcherSlice';
import FlightStatusTable from './FlightStatusTable';
import CXDatePicker from '../../../components/CXForm/CXDatePicker';
import EDGComparison from './EDGComparison';
import EDGSuccessRateList from './EDGSuccessRateList';

/**
 * Application home page component
 * @returns {JSX}
 */
const FlightStatusPage = () => {
    const dispatch = useAppDispatch();
    const { flightStatus } = useAppSelector(selectDispatcher);
    const [displayDate, setDisplayDate] = useState(moment.utc().format("YYYY/MM/DD"));
    const [edgComparisonParams, setEDGComparisonParams] = useState({
        ufi: "",
        serial: ""
    });
    const [viewEDGComparison, setViewEDGComparison] = useState(false);

    useEffect(() => {
        getFlightStatus(new Date(displayDate)).then(res => {
            dispatch(setFlightstatus(res));
        }).catch(err => {
            console.log(err);
        });
    }, [displayDate, dispatch]);

    const onClickView = (ufi: string, serial: string) => {
        if (!ufi || !serial) return;

        setEDGComparisonParams({ ufi, serial });
        setViewEDGComparison(true);
    };

    const onCloseView = () => {
        setViewEDGComparison(false);
    }

    return (
        <>
            <div className={"CathaySansBd"} style={{ fontSize: "32px", lineHeight: "36px" }}>Flight Status</div>
            <div className='mt-4'>
                <EDGSuccessRateList />
            </div>
            <div className={"section"} style={{ display: 'flex' }}>
                <CXDatePicker placeholder={"STD"} value={displayDate} onChange={setDisplayDate} zIndex={1029} />
            </div>
            <div className={"section"}>
                <FlightStatusTable flightStatus={flightStatus || []} onClickView={onClickView} />
            </div>
            {viewEDGComparison && <EDGComparison params={edgComparisonParams} onCloseView={onCloseView} />}
        </>
    );
};

export default FlightStatusPage;
