import { HTTP_REQUEST_TYPE, DISPATCHER_API, BEKOL_API } from "../constants/api";
import { sendHttpRequest } from "./api";

const getPastHourFlightPlan = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.GET_PAST_HOUR_FLIGHT_PLAN,
        {}
    );
    return resp;
};

const getFlightStatus = async (date: Date) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.FLIGHT_STATUS,
        {
            date: date
        }
    );
    return resp;
};

const getClosedFlightList = async (stdStart: string, stdEnd: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.CLOSE_FLIGHT_LIST,
        {
            stdStart,
            stdEnd
        }
    );
    return resp;
};

const getClosedFlightDetail = async (ufi: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.CLOSE_FLIGHT_DETAIL,
        {
            ufi
        }
    );
    return resp;
};

const getFlightDetail = async (ufi: string, airline: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        airline === 'UO' ?  BEKOL_API.FIGHT_DETAIL : DISPATCHER_API.FIGHT_DETAIL,
        {
            ufi
        }
    );
    return resp;
};

const postNotamFile = async (bodyFormData: FormData) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        DISPATCHER_API.POST_NOTAM_FILE,
        bodyFormData,
        true,
        true
    );
    return resp;
};

const getEDGComparisonDetail = async (ufi: string, serial: string) => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.GET_EDG_DETAIL,
        {
            ufi,
            serial
        }
    );
    return resp;
};

const getEDGSuccessRateList = async () => {
    const [, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DISPATCHER_API.GET_EDG_SUCCESS_RATE,
        {}
    );
    return resp;
};

export {
    getPastHourFlightPlan,
    getFlightStatus,
    getClosedFlightList,
    getClosedFlightDetail,
    getFlightDetail,
    postNotamFile,
    getEDGComparisonDetail,
    getEDGSuccessRateList
};
