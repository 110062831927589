import React, { useState, useEffect, useCallback } from 'react';
import { CRow, CCol } from '@coreui/react-pro';
import { Nav, Tab, Modal } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';

import { CXButton, CXDropDown } from '../../components/CXForm';
import PortPageRunwayContentContainer from './PortPageRunwayContentContainer'
import { addNewRunway, resetRunways, duplicateRunway, selectPortPageRunway } from '../../slice/portPageRunwaySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import portRunwayService, { IContentSecsShowObj } from '../../services/portPageRunway/portRunwayAPI';
import ReminderModal from '../../components/ReminderModal';
import { PDF_LAYOUT_MODE } from '../../constants/portPageRunwayConstant';
import { generatePdf } from '../../components/PDFGen/PDFGenBase';
import _ from 'lodash';

interface PortPageRunwayTabContainerProps {
    readOnly: boolean,
    isEditMode: boolean,
    setIsEditMode: Function,
    runways: Array<any>,
    calltoGetRunways: Function,
    portDetail: {
        airline: string;
        icao: string;
        cityName: string;
        countryName: string;
    };
}


const PortPageRunwayTabContainer = (props: PortPageRunwayTabContainerProps) => {
    const dispatch = useAppDispatch();
    const { readOnly, isEditMode, setIsEditMode, runways, calltoGetRunways, portDetail } = props;
    const [tabKey, setTabKey] = useState<number>(0);
    const [isAddNewRunway, setIsAddNewRunway] = useState<boolean>(false);
    const [isDupRunway, setIsDupRunway] = useState<boolean>(false);
    const [isSaveReminder, setIsSaveReminder] = useState<boolean>(false);
    const [pdfLayoutMode, setPDFLayoutMode] = useState<string>(PDF_LAYOUT_MODE.WHITE);
    const [isExportPDF, setIsExportPDF] = useState<boolean>(false);
    const { tempRunwayNames } = useAppSelector(selectPortPageRunway);
    const pdfLayoutModeOptions = [
        {
            text: PDF_LAYOUT_MODE.WHITE,
            value: PDF_LAYOUT_MODE.WHITE
        },
        {
            text: PDF_LAYOUT_MODE.DARK,
            value: PDF_LAYOUT_MODE.DARK
        }
    ]

    const initRunwayWhenFirstLandOn = () => {
        // new port don't have runway at the very beginning, need to create default one base on template
        const cleanObj = portRunwayService.getDefaultNewRunwayObject(portDetail);
        dispatch(addNewRunway({ cleanObj }));
    }

    const onAddNewRunway = useCallback((tabIndex = 0) => {
        const cleanObj = portRunwayService.getCleanRunwayObj({ ...runways[tabIndex] });//some unexpected errors come out when call in redux(portPageRunwaySlice)
        dispatch(addNewRunway({ cleanObj }));
        setTabKey(runways ? runways.length : tabKey);
        setIsAddNewRunway(true);
    }, [dispatch, portDetail, runways, tabKey])

    const onDuplicate = () => {
        dispatch(duplicateRunway({ tabIndex: tabKey }));
        setTabKey(runways ? runways.length : tabKey);
        setIsDupRunway(true);
    }

    const onCancelEdit = () => {
        setIsSaveReminder(true);
    }

    const onConfirmSave = useCallback((runwaySave, tabKey) => {
        const submitRunway = _.clone(runwaySave);
        if (submitRunway) {
            submitRunway.runway = tempRunwayNames[tabKey];
            submitRunway.lvoApproach = submitRunway.lvoApproach || [];
            submitRunway.approach = submitRunway.approach || [];
            portRunwayService.postRunwaySave(submitRunway).then(res => {
                if (res?.status === 500) {
                    // setResultFound(false);
                    console.log('postRunwaySave failed, res=', res)
                } else {
                    calltoGetRunways();
                }
            }).catch((err) => {
                console.log(err);
            });
        }

        setIsEditMode(false);
        setIsSaveReminder(false);
        setIsAddNewRunway(false);
        setIsDupRunway(false);
    }, [calltoGetRunways, setIsEditMode, tempRunwayNames])

    const onHideSaveReminderModal = () => {
        setIsSaveReminder(false);
    }

    const onCancelClick = () => {
        setIsSaveReminder(false);
    }

    const onConfirmCancelClick = () => {
        dispatch(resetRunways({ tabIndex: tabKey }));
        setIsEditMode(false);
        setIsSaveReminder(false);
        setIsAddNewRunway(false);
        setIsDupRunway(false);
        setTabKey(0);//go back to the 1st tab
    }

    const onCancelExportPDF = () => {
        setIsExportPDF(false);
    }

    const onConfirmExportPDF = () => {
        handleExportPDF();
        setIsExportPDF(false);
    }

    const handleExportPDF = () => {
        const currentRunway = runways[tabKey];
        const contentSecsShowObj: IContentSecsShowObj = {
            notes: true,
            lvoApproach: false, // remove / hidden at all time, ref task 2072 
            approach: true,
            takeOff: true,
            engineFailure: true
        };

        const content = portRunwayService.getPDFContentArray({ ...currentRunway, ...portDetail }, contentSecsShowObj);
        const outputPDFRunwayObject = {
            content,
            footer: ''
        };

        //{{Airport_ICAO}}_Runway_{{Runway_Name}}_{{DATE}}_{{TIME}}.pdf
        const dateLastUpdate = moment.utc(new Date(currentRunway.created)).format("DDMMMYYYY");
        const timeLastUpdate = moment.utc(new Date(currentRunway.created)).format("HHmmss");
        const fileName = `${portDetail.icao}_Runway_${currentRunway.runway}_${dateLastUpdate}_${timeLastUpdate}`;

        generatePdf(outputPDFRunwayObject).download(fileName);
    }

    useEffect(() => {
        if (runways && runways.length === 0) {
            initRunwayWhenFirstLandOn();
            setTabKey(tabKey);
        }
    }, [runways])

    const reminderModalProps = {
        isShow: isSaveReminder,
        onHide: onHideSaveReminderModal,
        onCancelClick,
        onConfirmCancelClick
    };

    const renderContent = () => {
        return (
            <>
                <Tab.Container id="left-tabs-example" activeKey={tabKey} onSelect={(key) => setTabKey(key ? parseInt(key) : 0)}>
                    <CRow className='tabRow ps-0'>
                        <CCol sm={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Nav variant="pills" className={"p-2 ps-0"}>
                                {runways && runways.map((tab, index) =>
                                    <Nav.Item key={`TabName_${tab.runway}`}>
                                        <Nav.Link className={`${tabKey === index ? "selectedTab nav-link-fix-selected" : "tabButton nav-link-fix"}`} eventKey={index}>{tab.runway}</Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </CCol>
                        {!readOnly && !isEditMode &&
                            <CCol sm={2} className={isEditMode ? "port-button" : ""}>
                                <CXButton outline={true} text={"Edit"} onClick={() => setIsEditMode(true)} disabled={isEditMode} fontSize={"16px"} width={"100%"} />
                            </CCol>
                        }
                        {!readOnly && !isEditMode &&
                            <CCol sm={2} className={isEditMode ? "port-button" : ""}>
                                <CXButton text={"Export as PDF"} onClick={() => { handleExportPDF(); }} fontSize={"16px"} width={"100%"} disabled={isEditMode} />
                            </CCol>
                        }
                        {!readOnly && isEditMode &&
                            <>
                                <CCol sm={2}></CCol>
                                <CCol sm={2} className={(isAddNewRunway || isDupRunway) ? "port-button" : ""}>
                                    <CXButton outline={true} text={"Add New Runway"} onClick={() => { onAddNewRunway(tabKey) }} disabled={isAddNewRunway || isDupRunway} fontSize={"16px"} width={"100%"} />
                                </CCol>
                            </>
                        }
                    </CRow>
                    <CRow>
                        {runways && runways.map((tab, index) =>
                            <Tab.Content key={`TabContent_${tab.runway}`}>
                                <Tab.Pane eventKey={index}>
                                    <DndProvider backend={HTML5Backend}>
                                        <PortPageRunwayContentContainer
                                            runwayTabData={tab}
                                            isEditMode={isEditMode}
                                            tabIndex={index}
                                        />
                                    </DndProvider>
                                </Tab.Pane>
                            </Tab.Content>
                        )
                        }
                        {!readOnly && isEditMode &&
                            <CRow>
                                <CCol sm={2} className={isDupRunway ? "port-button" : ""}>
                                    {!isAddNewRunway &&
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Duplicate"} onClick={() => onDuplicate()} fontSize={"16px"} width={"100%"} disabled={isDupRunway} /></div>
                                        </div>
                                    }
                                </CCol>
                                <CCol sm={6}></CCol>
                                <CCol sm={4}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={() => onCancelEdit()} fontSize={"16px"} width={"100%"} /></div>
                                        <div style={{ bottom: "40px", right: "40px", width: "200px", marginLeft: "15px" }}><CXButton text={"Save"} onClick={() => onConfirmSave(runways[tabKey], tabKey)} fontSize={"16px"} width={"100%"} /></div>
                                    </div>
                                </CCol>
                            </CRow>
                        }
                    </CRow>
                </Tab.Container>
                {!readOnly && isEditMode &&
                    <ReminderModal {...reminderModalProps} />
                }
                {!readOnly && !isEditMode &&
                    <Modal show={isExportPDF} onHide={() => setIsExportPDF(false)}>
                        <Modal.Header className="modal-header" closeButton>
                            <Modal.Title>Please choose the PDF layout mode you want </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CRow>
                                <CCol>
                                    <CXDropDown
                                        value={pdfLayoutMode}
                                        placeholder={"layout mode"}
                                        onChange={setPDFLayoutMode}
                                        options={pdfLayoutModeOptions}
                                        zIndex={1000} />
                                </CCol>
                            </CRow>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => onCancelExportPDF()} fontSize={"16px"} width={"100%"} /></div>
                            <div className='save-button'><CXButton text={"Confirm"} onClick={() => onConfirmExportPDF()} fontSize={"16px"} width={"100%"} /></div>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }

    return runways && runways?.length > 0
        ? renderContent()
        : null;
};

export default PortPageRunwayTabContainer;
