import React, {useState, useEffect, useCallback} from 'react';
import {useAppSelector} from '../../../app/hooks';
import {
  CRow,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody
} from '@coreui/react-pro';
import {CXButton, CXDropDown, CXTextInput} from '../../../components/CXForm';
import VisibilityDialog from '../../../components/crewfeedback/VisibilityDialog';
import {Modal} from 'react-bootstrap';
import _ from 'lodash';
import {checkPagePermission} from "../../../constants/simConstant";
import {selectUser} from "../../../slice/userSlice";
import {getFeedbackTags, getFeedbackLists, updateFeedback, getFeedbackRunways} from '../../../services/feedbackAPI';
import {FeedbackListItem} from '../../../components/crewfeedback/FeedbackListItem';
import CXFPConfigTextInput from "../../../components/CXForm/CXFPConfigTextInput";
import NoDataTableItem from '../../../components/AdminDashboard/NoDataTableItem';
import FeedbackTaggingPage from './FeedbackTaggingPage';
import {IFeedback, IFeedbackVisible} from "../../../interfaces/crewfeedback/feedback";

const FeedbackPage = () => {
  const {access} = useAppSelector(selectUser);
  const {isAdmin} = access as any;
  const [searchPort, setSearchPort] = useState<string>("");
  const [searchFlightNo, setSearchFlightNo] = useState<string>("");
  const [searchTab, setSearchTab] = useState<string>("");
  const [searchTag, setSearchTag] = useState<string>("");
  const [searchRunway, setSearchRunway] = useState<string>("");
  const [tagOptions, setTagOptions] = useState<Array<any>>([]);
  const [runwayOptions, setRunwayOptions] = useState<Array<any>>([]);
  const [addRemarkVis, setAddRemarkVis] = useState<boolean>(false);
  const [remarkIndex, setRemarkIndex] = useState<number>(0);
  const [updateRemarks, setUpdateRemarks] = useState<string>("");
  // Tab
  const [tabKey, setTabKey] = useState<number>(0);
  const [feedbackList, setFeedbackList] = useState<IFeedback[]>([]);
  // dialog for hide/show items
  const [displayHideFbDialog, setDisplayHideFbDialog] = useState<boolean>(false);
  const [toggleHiddenIndex, setToggleHiddenIndex] = useState<number>(-1);
  const [displayFbItem, setDisplayFbItem] = useState<IFeedbackVisible | null>(null);

  useEffect(() => {
    searchTags();
    searchRunways();
    search();
  }, [])

  const tabOptions = [
    {
      text: "ALL",
      value: "ALL"
    },
    {
      text: "ARR",
      value: "ARR"
    },
    {
      text: "DEP",
      value: "DEP"
    }
  ]

  const cellHeaderGroupArr = [
    'Port/Tab',
    'Flight no.',
    'Flight date',
    'Feedback content',
    'Like no.',
    'Saved no.',
    'Tags/Runway tags',
    'GalaCXy ID',
    'Remarks'
  ]

  const hiddenFbHeaders = [
    'Port',
    'Tab',
    'Flight no.',
    'Flight date',
    'Feedback content',
    'Tag',
    'Remarks'
  ]

  const tabLabelArr = [
    'Feedback List',
    'Tags'
  ]

  const searchTags = () => {
    getFeedbackTags().then(res => {
      let tagArr: { text: String, value: String }[] = [];
      tagArr.push({text: "ALL", value: "ALL"});
      res.tagsList.forEach(r => {
        tagArr.push({text: r, value: r});
      });
      setTagOptions(tagArr);
    }).catch(err => {
      console.log(err);
    });
  };

  const searchRunways = () => {
    getFeedbackRunways().then(res => {
      let runwaysArr: { text: String, value: String }[] = [];
      const tmpArr = res.runwaysList.map((r) => r.replace(/ARR |DEP /gi, ""));
      _.concat("ALL", _.uniq(tmpArr.sort())).forEach(r => {
        runwaysArr.push({text: r, value: r});
      });
      setRunwayOptions(runwaysArr);
    }).catch(err => {
      console.log(err);
    });
  };

  const search = () => {
    if (checkPagePermission([isAdmin])) {
      let tempObj = {};
      if (searchPort) {
        tempObj['icao'] = searchPort;
      }
      if (searchFlightNo) {
        tempObj['flightNo'] = searchFlightNo.toUpperCase();
        if (!isNaN(tempObj['flightNo'])) {
          tempObj['flightNo'] = "CPA" + tempObj['flightNo'].padStart(4, "0");
        }
      }
      if (searchTab && searchTab !== "ALL") {
        tempObj['type'] = searchTab.toLowerCase();
      }
      if (searchTag && searchTag !== "ALL") {
        tempObj['tags'] = searchTag;
      }
      if (searchRunway && searchRunway !== "ALL") {
        tempObj['runways'] = searchRunway;
      }

      getFeedbackLists(tempObj)
        .then((res) => {
          setFeedbackList(res.crewFeedbacks);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("You have no permission.\nPlease contract admin to grant access.");
    }
  };

  const onFbHiddenToggle = () => {
    updateFeedback({
      updatedId: feedbackList[toggleHiddenIndex]._id,
      updatedObj: {'hidden': !feedbackList[toggleHiddenIndex].hidden}
    })
      .then((res) => {
        search();
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
      setDisplayHideFbDialog(false);
    });
  };

  const onFbHiddenDialogToggle = (index: number) => {
    if (index < 0 || index >= feedbackList.length) return;
    const chooseItem = feedbackList[index];
    if (!chooseItem) return;
    setDisplayHideFbDialog(true);
    setToggleHiddenIndex(index);
    setDisplayFbItem({
      port: chooseItem.icao,
      tab: chooseItem.type,
      ufi: chooseItem.ufi,
      aircraftType: chooseItem.aircraftType,
      flightDate: chooseItem.std,
      content: chooseItem.text,
      tags: chooseItem.tags,
      runways: chooseItem.runways,
      remarks: chooseItem.remarks
    } as IFeedbackVisible)
  }

  const fbHiddenDialogConfirmText = () => {
    const activeItem = feedbackList[toggleHiddenIndex];
    return activeItem && !activeItem.hidden ? 'Hide' : 'Show';
  }

  const onAddRemarks = (index: number) => {
    setRemarkIndex(index);
    setAddRemarkVis(true);
    setUpdateRemarks(feedbackList[index].remarks || "");
  }

  const onClickAddRemarks = (index: number) => {
    updateFeedback({updatedId: feedbackList[index]._id, updatedObj: {'remarks': updateRemarks}})
      .then((res) => {
        search();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
    closeModal();
  }

  const onChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateRemarks(event.target.value);
  }

  const closeModal = () => {
    setAddRemarkVis(false);
  }

  const CellHeaderStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    color: "4C4C4C"
  };
  const NavLinkStyle = {
    fontSize: "18px",
    fontFamily: "CathaySans_Bd",
    color: "#116F9A",
    padding: "16px 22px",
    width: "200px",
    lineHeight: "1"
  };
  return (
    <>
      <div
        className={"CathaySansBd"}
        style={{fontSize: "32px", lineHeight: "36px"}}>
        Crew Feedback
      </div>
      <CNav variant="underline" role="tablist">
        {tabLabelArr.map((tabLabel, tabIndex) => (
          <CNavItem role="presentation" key={tabLabel || tabIndex}>
            <CNavLink
              style={NavLinkStyle}
              active={tabKey === tabIndex}
              component="button"
              role="tab"
              aria-controls={`feedbacklist-tab-pane-${tabLabel || tabIndex}`} // Update with actual ID
              aria-selected={tabKey === tabIndex}
              onClick={() => setTabKey(tabIndex)}>
              {tabLabel}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent style={{marginTop: "25px"}}>
        <CTabPane role="tabpanel" aria-labelledby="feedbacklist-tab-pane" visible={tabKey === 0}>
          {/* ---------Filter Area---------*/}
          <CRow>
            <CCol sm="2" style={{width: "11%"}}>
              <CXTextInput placeholder={"Port"} value={searchPort} onChange={setSearchPort} zIndex={1029}/>
            </CCol>
            <CCol sm="2" style={{width: "11%"}}>
              <CXTextInput
                placeholder={"Flight number"}
                value={searchFlightNo}
                onChange={setSearchFlightNo}
                zIndex={1029}/>
            </CCol>
            <CCol sm="1">
              <CXButton
                text={"Search"}
                disabled={(!(searchPort || searchFlightNo || searchTab || searchRunway || searchTag))}
                onClick={() => {
                  search()
                }}
                fontSize={"16px"} width={"100%"} height={"50px"}/>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="1">
              <CXDropDown
                value={searchTag}
                placeholder={"Tag"}
                onChange={setSearchTag}
                options={tagOptions}
                zIndex={1000}/>
            </CCol>
            <CCol sm="1">
              <CXDropDown
                value={searchRunway}
                placeholder={"Runway Tag"}
                onChange={setSearchRunway}
                options={runwayOptions}
                zIndex={1000}/>
            </CCol>
            <CCol sm="1">
              <CXDropDown
                value={searchTab}
                placeholder={"Tab"}
                onChange={setSearchTab}
                options={tabOptions}
                zIndex={1000}/>
            </CCol>
          </CRow>
          <CRow>
            {/* ---------Actual Feedback Content---------*/}
            <CCol className={'feedback-list'}>
              <CTable borderless hover>
                <CTableHead>
                  <CTableRow>
                    {cellHeaderGroupArr.map((title, index) => (
                      <CTableHeaderCell scope="col" key={index}>
                        <div className={"CathaySansRg"} style={CellHeaderStyle} key={index}>
                          {title}
                        </div>
                      </CTableHeaderCell>
                    ))}
                    <CTableHeaderCell style={{fontSize: "10rem"}} scope="col"></CTableHeaderCell>
                    <CTableHeaderCell style={{fontSize: "10rem"}} scope="col"></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {!!feedbackList && feedbackList.length !== 0 ?
                    feedbackList.map((feedback, index) =>
                      <FeedbackListItem key={`FeedbackListItem_${index}`} index={index} feedback={feedback}
                                        hideFeedback={onFbHiddenDialogToggle} addRemarks={onAddRemarks}/>)
                    :
                    (<NoDataTableItem colSpan={3} message={"There is no feedback"}/>)
                  }
                </CTableBody>
              </CTable>
            </CCol>
          </CRow>
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="feedbacklist-tab-pane" visible={tabKey === 1}>
          <FeedbackTaggingPage/>
        </CTabPane>
        {/* --------- Add/Edit Remarks --------- */}
        <Modal show={addRemarkVis} onHide={closeModal} className='feedback-modal'>
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title>
              {"Add/Edit Remarks"}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height: "160px"}}>
            <CRow>
                  <span>
                    Remarks
                  </span>
              <CXFPConfigTextInput
                placeholder={"Remarks"}
                value={(feedbackList.length && feedbackList[remarkIndex]) ? feedbackList[remarkIndex].remarks : ""}
                onChangeField={onChangeModal}
                field="remarks"
                errMsg={"Required"}
                zIndex={1031}/>
            </CRow>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => closeModal()}
                                                     fontSize={"16px"} width={"100%"}/></div>
            <div className='save-button'><CXButton text={"Update"}
                                                   onClick={() => onClickAddRemarks(remarkIndex)} fontSize={"16px"}
                                                   width={"100%"}/></div>
          </Modal.Footer>
        </Modal>
        {/* --------- Hide/Show Feedback --------- */}
        {displayHideFbDialog && (
          <VisibilityDialog
            title="Hide feedback"
            description="Are you sure to hide this crew feedback from Flight Folder app?"
            tableHeaders={hiddenFbHeaders}
            data={displayFbItem}
            btnConfirmText={fbHiddenDialogConfirmText()}
            onConfirm={onFbHiddenToggle}
            btnCancelText={'Cancel'}
            onCancel={() => setDisplayHideFbDialog(false)}
          />
        )}
      </CTabContent>
    </>
  );
};

export default FeedbackPage;
