import React from 'react';
import { CToast, CToastBody, CToastClose } from '@coreui/react-pro';
import './style/style.scss';

interface ICXToastProps {
  message: string;
  autoHide?: boolean;
  delay?: number;
  type?: string;
}

const CXToast = ({ message, autoHide=true, delay=5000, type="success" }: ICXToastProps) => {
  /*
    ----------------------------- Sample component ------------------------------
    const [text1, setText1] = useState("");
    <CXToast value={text1} placeholder={"Flight Number"} onChange={setText1} />
    ----------------------------- Sample component ------------------------------
  */
  const getToastClass = () => {
    switch(type) {
      case "success":
        return "cx-toast";
      case "error":
        return "cx-error-toast";
    }
  }

  return (
    <CToast autohide={autoHide} delay={delay} visible={true} className={`${getToastClass()} align-items-center`}>
      <div className="d-flex">
        <CToastBody className="cx-toast-body">{message}</CToastBody>
        <CToastClose className="me-2 m-auto"  />
      </div>
    </CToast>
  );
};

export default CXToast;