import { createSlice } from "@reduxjs/toolkit";
import { StringLiteral } from "typescript";
import { RootState } from "../app/store";
import { WEEKDAYS_MULTISELECT, WEEKDAYS_MULTISELECT_VALUE } from "../constants/simConstant";

interface ICrew {
  galacxyId: string;
  dutyCode: string;
  crewName: string;
}

interface ILoadsheet {
  type: string;
  raw: string;
  zfw: number | string;
  version: string;
}

interface IDigitalRefuelObject {
  type: string;
  count: number;
}

interface IAdditionalInfo {
  extraFuel: number;
  snn: string;
  loadSheet: ILoadsheet[];
  unsavedLoadsheet?: ILoadsheet[];
  loadSheetTemplate: ILoadsheet[];
  digitalRefueling: IDigitalRefuelObject[];
}

interface ISimFlightPlan {
  std: string;
  aircraftType: string;
  aircraftReg: string;
  aircraftOp: string;
  ufi: string;
  tag: string;
  status: string;
  flightNum: string;
  flightNumSuffix: string;
  createdBy: string;
  createTime: string;
  simCode: string;
  templateName: string;
  crewlist: ICrew[];
  additionalInfo: IAdditionalInfo;
}

interface ISimSlice {
  simFlightPlans: ISimFlightPlan[];
  simFlightPlanTemplates: ISimFlightPlan[];
  simOFPFormInEdit: ISimFlightPlan;
  aircrafts: IAircraft[];
  snnRules: ISNNRule[];
  snnRulesFormInEdit: ISNNRule;
  appendixRules: IAppendixRule[];
  appendixFiles: IAppendixFile[];
  appendixFilesMap: AppendixFilesMap
}

interface IAircraft {
  _id: string,
  aircraftICAO: string,
  airline: string,
  fleetType: string,
  edt: {
    EDTO060: string,
    EDTO120: string,
    EDTO180: string,
    EDTO195: string,
    EDTO207: string,
    EDTO240: string,
    TaxiFuelRate: string
  },
  regs: Reg[];
}

interface Reg {
  reg: string,
  aircraftFullname: string
}

export interface ExternalCrewlist {
  departurePort: string;
  arrivalPort: string;
  flightNumber: string;
  aircraftRegistration: string;
  stdUTC: string;
  originDate: string;
  crews: ExternalCrew[];
  flightNo: string;
  registration: string;
  flightDate: string;
  ufi: string;
  updatedAt: string;
  createdAt: string;
}

export interface ExternalCrew {
  _id: string;
  currentErn: string;
  badgeName: string;
  rankCode: string;
  recencyDueDate: RecencyDueDate[];
  qualification: Qualification[];

}

export interface RecencyDueDate {
  _id: string;
  //non-capital letter because of backend and db schema
  takeoffhistory: string[];
  landinghistory: string[];
  takeoffDueDate: string;
  landingDueDate: string;
  //non-capital letter because of backend and db schema
  aircraftype: string;
}

interface Qualification {
  code: string;
  dueDate: string;
}

interface IAircraftRequest {
  aircraftICAO: string,
  airline: string,
  fleetType: string,
  reg: string;
  aircraftFullname: string;
}

interface AppendixFilesMap {
  [key: string]: string;
};
interface ISNNRule {
  _id: string;
  airline: string;
  flightNo: string;
  aircraftType: string;
  registration: string;
  dep: string[];
  arr: string[];
  altn: string;
  era: string;
  startAt: string;
  endAt: string;
  intervalStartTime: string;
  intervalEndTime: string;
  intervalEndDay: string;
  intervalDays: any[];
  timeRange: string;
  isInterval: boolean;
  waypoint: string;
  countryICAOCode: string;
  isActive: boolean;
  createAt?: string;
  createBy?: string;
  snn: string;
  version: number;
}

interface ISNNRuleRequest {
  airline: string;
  flightNo: string;
  aircraftType: string;
  registration: string;
  dep: string;
  arr: string;
  altn: string;
  era: string;
  startAt: string;
  endAt: string;
  intervalStartTime: string;
  intervalEndTime: string;
  intervalEndDay: string;
  intervalDays: number[];
  timeRange: string;
  isInterval: boolean;
  waypoint: string;
  countryICAOCode: string;
  isActive: boolean;
  snn: string;
  createBy?: string;
  version: number;
}

interface IAppendixRule {
  _id: string;
  airline: string;
  flightNo: string;
  aircraftType: string;
  registration: string;
  dep: string[];
  arr: string[];
  altn: string;
  era: string;
  expiry: string;
  review: string;
  files: IAppendFile[];
}

interface IAppendFile {
  pdfName: string,
  uploadDate: string,
}

interface IAppendixRuleRequest {
  airline: string;
  flightNo: string;
  aircraftType: string;
  registration: string;
  dep: string;
  arr: string;
  altn: string;
  era: string;
  expiry: string;
  review: string;
  files: string[];
}

interface IAppendixFile {
  eTag: string,
  pdfName: string,
  uploadDate: string,
  size: string
}

interface ISNNSearch {
  airline: string;
  flightNo: string;
  aircraftType: string;
  registration: string;
  dep: string;
  arr: string;
  altn: string;
  era: string;
  snn: string;
}

interface IAppendixSearch {
  aircraftType: string;
  registration: string;
  files: string;
  expiry: string;
  review: string;
  airline: string;
  dep: string;
  arr: string;
  altn: string;
  era: string;
}

interface IAddNewPortRequest {
  galacxyId: string;
  airline: string;
  icao: string;
  iata: string;
  name: string;
  cityName: string;
  countryName: string;
  createdBy: string;
}

interface IAddPortElementRequest {
  galacxyId: string;
  airline: string;
  icao: string;
  type: string;
  tabId?: string;
}

interface IDeletePortElementRequest {
  galacxyId: string;
  airline: string;
  icao: string;
  type: string;
  tabId?: string;
  sections?: {tabId: string, secId: string}
}

const initialNewOFPForm: ISimFlightPlan = {
  std: "",
  aircraftType: "",
  aircraftOp: "",
  aircraftReg: "",
  ufi: "",
  tag: "",
  status: "",
  flightNum: "",
  flightNumSuffix: "",
  createdBy: "",
  createTime: "",
  simCode: "",
  crewlist: [],
  templateName: '',
  additionalInfo: {
    extraFuel: 0,
    snn: "",
    loadSheet: [],
    unsavedLoadsheet: [],
    loadSheetTemplate: [],
    digitalRefueling: [],
  },
};

const initialNewSNNRule: ISNNRule = {
  _id: "",
  airline: "ALL",
  flightNo: "ALL",
  aircraftType: "ALL",
  registration: "ALL",
  dep: ['ZZZZ'],
  arr: ['ZZZZ'],
  altn: "ZZZZ",
  era: "ZZZZ",
  startAt: new Date().toISOString().toString(),
  endAt: "2047-06-30T00:00:00.000+00:00",
  timeRange: "BOTH",
  intervalStartTime: "",
  intervalEndTime: "",
  intervalEndDay: "0",
  intervalDays: [],
  isInterval: false,
  waypoint: "ALL",
  countryICAOCode: "ALL",
  isActive: true,
  snn: "",
  createBy: "",
  version: 1,
}

const initialNewHKESNNRule: ISNNRule = {
  _id: "",
  airline: "HKE",
  flightNo: "ALL",
  aircraftType: "ALL",
  registration: "ALL",
  dep: ['ZZZZ'],
  arr: ['ZZZZ'],
  altn: "ZZZZ",
  era: "ZZZZ",
  startAt: new Date().toISOString().toString(),
  endAt: "2047-06-30T00:00:00.000+00:00",
  timeRange: "BOTH",
  intervalStartTime: "",
  intervalEndTime: "",
  intervalEndDay: "0",
  intervalDays: [],
  isInterval: false,
  waypoint: "ALL",
  countryICAOCode: "ALL",
  isActive: true,
  snn: "",
  createBy: "",
  version: 1,
}

const initialSNNReducer = {
  body: initialNewSNNRule,
  isAddNewMode: false,
  isDuplicated: false,
  isAddNewSNNRulesLightBoxVisible: false,
  isViewSNNRulesLightBoxVisible: false,
  isDeleteSNNRulesLightBoxVisible: false
}

const initialNewAppendixRule: IAppendixRule = {
  _id: "",
  airline: "ALL",
  flightNo: "ALL",
  aircraftType: "ALL",
  registration: "ALL",
  dep: ["ZZZZ"],
  arr: ["ZZZZ"],
  altn: "ZZZZ",
  era: "ZZZZ",
  expiry: "2047-06-30T00:00:00.000+00:00",
  review: "2047-06-30T00:00:00.000+00:00",
  files: []
}


const initialNewHKEAppendixRule: IAppendixRule = {
  _id: "",
  airline: "HKE",
  flightNo: "ALL",
  aircraftType: "ALL",
  registration: "ALL",
  dep: ["ZZZZ"],
  arr: ["ZZZZ"],
  altn: "ZZZZ",
  era: "ZZZZ",
  expiry: "2047-06-30T00:00:00.000+00:00",
  review: "2047-06-30T00:00:00.000+00:00",
  files: []
}

const initialAppendixReducer = {
  body: initialNewAppendixRule,
  isAddNewMode: false,
  isDuplicated: false,
  isAddNewAppendixLightBoxVisible: false,
  isViewAppendixLightBoxVisible: false,
  isSelectAppendixLightBoxVisible: false,
  isDeleteAppendixLightBoxVisible: false
}

const initialSNNSearch: ISNNSearch = {
  airline: "",
  flightNo: "",
  aircraftType: "",
  registration: "",
  dep: "",
  arr: "",
  altn: "",
  era: "",
  snn: ""
}

const initialAppendixRuleSearch: IAppendixSearch= {
  aircraftType: "",
  registration: "",
  files: "",
  expiry: "",
  review: "",
  airline: "",
  dep: "",
  arr: "",
  altn: "",
  era: ""
}

const initialState: ISimSlice = {
  simFlightPlans: [],
  simFlightPlanTemplates: [],
  simOFPFormInEdit: initialNewOFPForm,
  aircrafts: [],
  snnRules: [],
  snnRulesFormInEdit: initialNewSNNRule,
  appendixRules: [],
  appendixFiles: [],
  appendixFilesMap: {}
}

const simSlice = createSlice({
  name: "sim",
  initialState,
  reducers: {
    setSimFlightPlans: (state, { payload }) => {
      state.simFlightPlans = payload || [];
    },
    updateSimFlightPlans: (state, { payload }) => {
      let targetIndex = Object.values(state?.simFlightPlans || {} ).findIndex((flightPlan) => flightPlan.ufi === payload?.ufi);
      if (targetIndex !== undefined) {
        state.simFlightPlans[targetIndex] = payload;
      }
    },
    setSimFlightPlanTemplates: (state, { payload }) => {
      state.simFlightPlanTemplates = payload || [];
    },
    setSimOFPFormInEdit: (state, { payload }) => {
      let simOFPForm = state.simOFPFormInEdit;
      simOFPForm = {
        ...simOFPForm,
        ...payload
      }
      state.simOFPFormInEdit = simOFPForm;
    },
    addCrews: (state, { payload: { crewlistString } }) => {
      let galacxyIdArray = crewlistString.split(",");
      let crewlist = galacxyIdArray.map(galacxyId => { return { galacxyId: galacxyId, dutyCode: "" } });
      state.simOFPFormInEdit.crewlist = [
        ...state.simOFPFormInEdit.crewlist,
        ...crewlist
      ];
    },
    setDutyCode: (state, { payload: { galacxyId, dutyCode } }) => {
      let crewIndex = state.simOFPFormInEdit.crewlist?.findIndex(crew => crew.galacxyId === galacxyId);
      if (!!crewIndex || crewIndex >= 0) {
        let crew = state.simOFPFormInEdit.crewlist[crewIndex];
        crew.dutyCode = dutyCode;
        state.simOFPFormInEdit.crewlist[crewIndex] = crew;
      }
    },
    deleteCrew: (state, { payload: { galacxyId } }) => {
      let crewlist = state.simOFPFormInEdit.crewlist;
      crewlist = crewlist.filter(crew => crew.galacxyId !== galacxyId);
      state.simOFPFormInEdit.crewlist = crewlist
    },
    setAdditionalInfoForm: (state, { payload }) => {
      let additionalInfoForm = state.simOFPFormInEdit.additionalInfo;
      additionalInfoForm = {
        ...additionalInfoForm,
        ...payload
      }
      state.simOFPFormInEdit.additionalInfo = additionalInfoForm;
    },
    clearSimOFPForm: (state) => {
      state.simOFPFormInEdit = initialNewOFPForm;
    },
    addUnsavedLoadsheet: (state, { payload: { loadsheet } }) => {
      let unsavedLoadsheet = state?.simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet || [];
      state.simOFPFormInEdit.additionalInfo.unsavedLoadsheet = [
        ...unsavedLoadsheet,
        loadsheet
      ]
    },
    setUnsavedLoadsheet: (state, { payload: { index, loadsheet } }) => {
      if (!!state?.simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet) {
        state.simOFPFormInEdit.additionalInfo.unsavedLoadsheet[index] = loadsheet;
      }
    },
    setUnsavedLoadsheetRaw: (state, { payload: { index, raw } }) => {
      if (!!state?.simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet) {
        state.simOFPFormInEdit.additionalInfo.unsavedLoadsheet[index].raw = raw;
      }
    },
    setUnsavedLoadsheetZfw: (state, { payload: { index, zfw } }) => {
      if (!!state?.simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet) {
        // Check zfw is string or number
        if(typeof zfw === 'string'){
          state.simOFPFormInEdit.additionalInfo.unsavedLoadsheet[index].zfw = parseInt(zfw);
        } else {
          state.simOFPFormInEdit.additionalInfo.unsavedLoadsheet[index].zfw = zfw;
        }
      }
    },
    deleteUnsavedLoadsheet: (state, { payload: { index } }) => {
      if (!!state?.simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet) {
        let unsavedLoadsheet = state?.simOFPFormInEdit?.additionalInfo?.unsavedLoadsheet || [];
        unsavedLoadsheet = unsavedLoadsheet.filter((loadSheet, loadsheetIndex) => loadsheetIndex !== index);
        state.simOFPFormInEdit.additionalInfo.unsavedLoadsheet = unsavedLoadsheet;
      }
    },
    setAircrafts: (state, { payload }) => {
      state.aircrafts = payload || [];
    },
    setSNNRules: (state, { payload }) => {
      let resolvedSNN = [...payload];
      resolvedSNN = resolvedSNN.map((snn) => {
        if((snn.intervalDays && snn.intervalDays.length === 0) || !snn.intervalDays){
          return snn;
        }
        let orderCounter = -1;
        snn.intervalDays = WEEKDAYS_MULTISELECT_VALUE.map((option) => {
          if(snn.intervalDays.includes(option.value)){
            orderCounter += 1
            return {
              value: option.value,
              text: WEEKDAYS_MULTISELECT[option.value],
              selected: true,
              order: orderCounter
            }
          }
          return option;
        })
      })
      console.log(state.snnRules)
      state.snnRules = payload || [];
    },
    setSNNRuleFormInEdit: (state, { payload }) => {
      let suuRulesForm = state.snnRulesFormInEdit;
      suuRulesForm = {
        ...suuRulesForm,
        ...payload
      }
      state.snnRulesFormInEdit = suuRulesForm;
    },
    setAppendixRules: (state, { payload }) => {
      state.appendixRules = payload || [];
    },
    setAppendixFiles: (state, { payload }) => {
      state.appendixFiles = payload || [];
    },
    setAppendixFilesMap: (state, { payload }) => {
      state.appendixFilesMap = payload || [];
    },
  }
});

const selectSim = (state: RootState) => state.sim;
const {
  setSimFlightPlans,
  updateSimFlightPlans,
  setSimFlightPlanTemplates,
  setSimOFPFormInEdit,
  setAdditionalInfoForm,
  addCrews,
  setDutyCode,
  deleteCrew,
  clearSimOFPForm,
  addUnsavedLoadsheet,
  setUnsavedLoadsheet,
  setUnsavedLoadsheetRaw,
  setUnsavedLoadsheetZfw,
  deleteUnsavedLoadsheet,
  setAircrafts,
  setSNNRules,
  setAppendixRules,
  setAppendixFiles,
  setAppendixFilesMap
} = simSlice.actions;

export {
  simSlice,
  selectSim,
  setSimFlightPlans,
  updateSimFlightPlans,
  setSimFlightPlanTemplates,
  setSimOFPFormInEdit,
  setAdditionalInfoForm,
  addCrews,
  setDutyCode,
  deleteCrew,
  clearSimOFPForm,
  addUnsavedLoadsheet,
  setUnsavedLoadsheet,
  setUnsavedLoadsheetRaw,
  setUnsavedLoadsheetZfw,
  deleteUnsavedLoadsheet,
  setAircrafts,
  setSNNRules,
  setAppendixRules,
  setAppendixFiles,
  setAppendixFilesMap,
  initialNewSNNRule,
  initialNewHKESNNRule,
  initialSNNSearch,
  initialAppendixRuleSearch,
  initialNewAppendixRule,
  initialNewHKEAppendixRule,
  initialSNNReducer,
  initialAppendixReducer
};
export type { ISimSlice, ICrew, ISimFlightPlan, ILoadsheet, IAircraft, IAircraftRequest, ISNNRule, ISNNRuleRequest, IAppendixRuleRequest, IAppendixRule, IAppendFile, IAppendixFile, ISNNSearch, IAppendixSearch, IAddNewPortRequest, IAddPortElementRequest, IDeletePortElementRequest};

export default simSlice.reducer;
