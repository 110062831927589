import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import { getClosedFlightDetail } from '../../services/dispatchAPI';
import { selectDispatcher, setCloseFlightDetail } from '../../slice/dispatcherSlice';
import CloseFlightDetailContent from '../../components/AdminDashboard/CloseFlightDetailContent';
import { checkPagePermission } from '../../constants/simConstant';

/**
 * Application home page component
 * @returns {JSX}
 */

const CloseFlightDetailPage = () => {
  const params = useParams()
  const { ufi, closeFlightPackageIdentifier } = params;
  const dispatch = useAppDispatch();
  const { access } = useAppSelector(selectUser);
  const { closeFlightDetail } = useAppSelector(selectDispatcher);
  const { isAdmin, isCXDispatch, isCXAudit } = access as any;

  useEffect(() => {
    if (checkPagePermission([isAdmin, isCXDispatch, isCXAudit])) {
      getClosedFlightDetail(ufi).then(res => {
        dispatch(setCloseFlightDetail(res));
      }).catch(err => {
        console.log(err);
      });
    } else {
      alert("You have no permission.\nPlease contract admin to grant access.");
    }
  }, [ufi, dispatch, isAdmin, isCXDispatch, isCXAudit]);
  return (
    <>
      <CloseFlightDetailContent user={"cx"} ufi={ufi} closeFlightPackageIdentifier={closeFlightPackageIdentifier} closeFlightDetail={closeFlightDetail}/>
    </>
  );
};

export default CloseFlightDetailPage;