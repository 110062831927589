import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useLocation } from "react-router-dom";

import GenericTable from '../../components/AdminDashboard/GenericTable';
import HeadTitle from '../../components/AdminDashboard/HeadTitle';
import CXDropDown from '../../components/CXForm/CXDropDown';
import { GENERIC_TABLE_TYPES } from '../../constants/genericTableConstant';
import { getUODefects } from '../../services/bekolDispatchAPI';
import { selectDispatcher, resetDefects, setDefects, resetCXDefects, setCXDefects } from '../../slice/dispatcherSlice';
import { selectUser } from '../../slice/userSlice';
import { FLIGHT_PLAN_CONFIG_PATHS, UO_PATHS } from "../../constants/path";
import { getCXDefects, postCXDefectRemarkSave, IDefectRemarkSavePayload } from '../../services/flightPlanConfigAPI'
import { checkPagePermission } from '../../constants/simConstant';
import CXDefectTable from '../../components/AdminDashboard/CXDefectTable'
import { CRow, CCol} from '@coreui/react-pro';
import CXFPConfigTextInput from '../../components/CXForm/CXFPConfigTextInput';


/**
 * Application home page component
 * @returns {JSX}
 */


const SharedDefectsPage = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { defect: uoDefects, cxDefects } = useAppSelector(selectDispatcher);
  const { access } = useAppSelector(selectUser);
  const { isAdmin, isUODispatch, isCXDispatch } = access as any;
  const [defectType, setDefectType] = useState<string>("P");
  const [searchCriteria, setSearchCriteria] = useState<any>({});
  const [defectTableData, setDefectTableData] = useState<any>([]);
  const [defectTableDataNonFiltered, setDefectTableDataNonFiltered] = useState<any>([]);
  const hasAccessForCX = checkPagePermission([isAdmin, isCXDispatch]);
  const hasAccessForUO = checkPagePermission([isAdmin, isUODispatch]);
  const isCXDefects = pathname === FLIGHT_PLAN_CONFIG_PATHS.defects;
  const isUODefects = pathname === UO_PATHS.defect;

  const defectTypeOptions = [
    {
      text: "ALL",
      value: "ALL"
    },
    {
      text: "P",
      value: "P"
    }
  ]
  const cxDefectColName: Array<string> = [
    "Aircraft",
    "ADD#",
    "ADD Title",
    "ADD Detail",
    "MEL ID",
    "Expiry Date UTC",
    "Change Time UTC",
    "Deleted",
    "Remark"
  ];

  const uoDefectColName: Array<string> = [
    "Aircraft",
    "ADD#",
    "ADD Title",
    "ADD Detail",
    "MEL ID",
    "Expiry Date UTC",
    "Change Time UTC",
    "Deleted",
  ];

  const searchItems: Array<searchFieldType> = [
    {
      key: "aircraft",
      placeholder: "Aircraft"
    }
  ];

  interface IDefectsContainer {
    headTitle: string,
    tableType: string,
    tableColNames: Array<any>,
    tableData: Array<any>,
  }

  interface searchFieldType{
    key: string
    placeholder: string,
  }

  let defectsConObj: IDefectsContainer = {
    headTitle: '',
    tableType: '',
    tableColNames: [],
    tableData: []
  }

  const fillDefectData = (defectListResponse: any) => {
    if(!defectListResponse) return;
    let defectData = defectListResponse.defects;
    setDefectTableDataNonFiltered(defectData);
    setDefectTableData(defectData)
  }

  //only support single feild search
  const searchSingleFieldDefects = (searchField, searchValue) => {
    let filteredResult = defectTableDataNonFiltered.filter((data: any)=> 
      searchValue === '' ? 
        data: 
        data[searchField].includes(searchValue)
    );
    setDefectTableData(filteredResult);
  }

  const onChangeSearchForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = {
      ...searchCriteria
    },
    changedField = event.target.name,
    changedValue = event.target.value;
    tempObj[changedField] = event.target.value;
    setSearchCriteria(tempObj);
    searchSingleFieldDefects(changedField, changedValue);
  }

  useEffect(() => {
    const getDefect = async () => {
      let response;
      if (isUODefects && hasAccessForUO) {
        response = await getUODefects(defectType);
      } else if (isCXDefects && hasAccessForCX) {
        response = await getCXDefects(defectType);
      }

      if (!!response) {
        fillDefectData(response);
      }
    }
    getDefect();
  }, [defectType, dispatch, hasAccessForCX, hasAccessForUO, isCXDefects, isUODefects])

  return (
    <>
      <HeadTitle headTitle='Defects' />
      <div>
        <CRow>
          <CCol sm="1">
            <CXDropDown
              value={defectType}
              placeholder={"Defect Type"}
              onChange={setDefectType}
              options={defectTypeOptions}
              zIndex={1319} 
            />
          </CCol>
          <CCol sm="2">
            {searchItems.map((searchField, index) => (
              <CXFPConfigTextInput
                  placeholder={searchField.placeholder}
                  value={searchCriteria[searchField.key]}
                  onChangeField={onChangeSearchForm}
                  promptChangeFieldTrigger={true}
                  field={searchField.key}
                  zIndex={1029} 
                />
            ))}
          </CCol>
        </CRow>
      </div>
      <div className={"section"}>
        {isUODefects &&
          <GenericTable
            type={GENERIC_TABLE_TYPES.defect}
            fieldNames={uoDefectColName}
            data={defectTableData || []}
          />}
        {isCXDefects &&
          <CXDefectTable
            type={GENERIC_TABLE_TYPES.defect}
            fieldNames={cxDefectColName}
            data={defectTableData || []}
            saveRemark={
              (payload: IDefectRemarkSavePayload) => {
                postCXDefectRemarkSave(payload).then((res) => {
                  if (!!res) {
                    const updatedCXDefects = defectTableData.map(defect =>
                      defect._id === res._id ?
                        { ...defect, remark: res.remark }
                        : defect
                    );
                    fillDefectData({ defects: updatedCXDefects });
                  }
                }).catch((err) => {
                  console.log(err);
                });
              }
            }
          />}
      </div>
    </>
  );
};

export default SharedDefectsPage;