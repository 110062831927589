import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CHeader } from '@coreui/react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { CathayPacificLogoIcon, MenuIcon, ProfileIcon } from '../assets/images';
import { selectApp, setAppFlags } from '../slice/appSlice';
import { selectAuth, logout } from '../slice/authSlice';
import { clearUser } from '../slice/userSlice';
import { logoutRedirect } from '../config/authConfig';

const AppHeader = () => {
  const dispatch = useAppDispatch();
  const { isSidebarVisible } = useAppSelector(selectApp);
  const { userName } = useAppSelector(selectAuth);

  const signOutClickHandler = useCallback(() => {
    dispatch(logout());
    dispatch(clearUser());
    logoutRedirect();
  }, [dispatch]);

  const ProfileAndSignOutButton = () => {
    return <div
      className={"d-flex align-items-center"}
      style={{ position: 'absolute', top: "30px", right: "24px", cursor: "pointer" }}
      onClick={signOutClickHandler}
    >
      <ProfileIcon width={24} height={24} />
      <div className={"CathaySansBd"} style={{ fontSize: "16px", lineHeight: "20.48px", color: "#4C4C4C", paddingLeft: 20 }}>{(!!userName) ? userName : "Guest"}</div>
    </div>
  };

  return <CHeader position={"sticky"} style={{ height: "72px", zIndex: 1031 }}>
    <button className={"menuButton"} style={{ position: 'absolute', top: "28px", left: "21.33px" }} onClick={() => dispatch(setAppFlags({ field: "isSidebarVisible", value: !isSidebarVisible }))}>
      <MenuIcon width={32} height={24} />
    </button>
    <Link to={"/"} style={{ position: 'absolute', top: "21.19px", left: "72px" }}>
      <CathayPacificLogoIcon width={"23.51px"} height={"28.88px"} />
    </Link>
    <Link to="/" style={{ position: 'absolute', top: "29.39px", left: "132px" }}>
      <div className={"CathaySansRg"} style={{ fontSize: "17px", lineHeight: "21.76px", color: "#4C4C4C" }}>Flight Folder Console</div>
    </Link>
    <ProfileAndSignOutButton />

  </CHeader>
};

export default AppHeader;