import React, {useEffect, useState} from 'react';
import {CRow, CCol} from '@coreui/react-pro';
import _ from 'lodash';

import {useAppSelector} from '../../../app/hooks';
import {selectUser} from "../../../slice/userSlice";
import {CXButton, CXDropDown, CXTextInput} from '../../../components/CXForm';
import {
  TAB_OPTIONS,
  TAG_LIST_FIELDS,
  OPERATION_TYPE,
  TAB_OPTIONS_MUTIL_SEL_DD_VALUE_MAPPING,
  TAG_EXISTS_ALERT_MSG,
  HIDE_TAG_CONFIRM_MSG,
  NO_ACCESS_ALERT_MSG
} from '../../../constants/crewFeedbackTaggingPageConstant';
import CrewFeedbackTaggingTable from '../../../components/crewfeedback/CrewFeedbackTaggingTable';
import {GENERIC_TABLE_TYPES} from '../../../constants/genericTableConstant';
import CrewFeedbackTagModal from '../../../components/crewfeedback/CrewFeedbackTagModal';
import {ITagModal, ITagsSearch} from '../../../interfaces/crewfeedback/crewFeedbackTagging';
import {getFeedbackTaggingList, updateFeedback, updateTagging} from '../../../services/feedbackAPI';
import {checkPagePermission} from "../../../constants/simConstant";
import {IFeedbackTag, IFeedbackTagVisible} from "../../../interfaces/crewfeedback/feedback";
import TagVisibilityDialog from "../../../components/crewfeedback/TagVisibilityDialog";

const FeedbackTaggingPage = () => {
  const {access} = useAppSelector(selectUser);
  const {isAdmin} = access as any;

  const initTagsSearch: ITagsSearch = {port: "", tab: ""};
  const initTagModal: ITagModal = {
    _id: "",
    tag: "",
    port: [],
    tab: [],
    hidden: false
  };
  const hiddenTagHeaders = [
    'Tags',
    'Port',
    'Tab'
  ]

  const [tagsList, setTagsList] = useState<Array<IFeedbackTag>>([]);
  const [tagsListSearch, setTagsListSearch] = useState<Array<any>>([]);
  const [tagsSearch, setTagsSearch] = useState<ITagsSearch>(initTagsSearch);
  const [isAddNewTag, setIsAddNewTag] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [tagModal, setTagModal] = useState<ITagModal>(initTagModal);

  // dialog for hide/show items
  const [displayVisDialog, setDisplayVisDialog] = useState<boolean>(false);
  const [currentEditVisItem, setCurrentEditVisItem] = useState<IFeedbackTagVisible | null>(null);

  const onClickSearch = () => {
    if (!_.isEmpty(tagsList)) {
      let tagsListTemp = tagsList;
      if (!_.isEmpty(tagsSearch.port)) {
        tagsListTemp = tagsListTemp.filter(tagsItem => tagsItem.port.includes(tagsSearch.port.toString().toUpperCase()));
      }
      if (!_.isEmpty(tagsSearch.tab) && tagsSearch.tab !== "ALL") {
        tagsListTemp = tagsListTemp.filter(tagsItem => tagsItem.tab.includes(tagsSearch.tab.toString().toUpperCase()));
      }
      setTagsListSearch(tagsListTemp);
    }
    setTagsSearch(initTagsSearch);
  }

  const getTagsList = () => {
    //call api to get tags list
    getFeedbackTaggingList().then(res => {
      if (res?.status === 500) {
        console.log('get Feedback Tagging List failed, res=', res);
      } else if (!_.isEmpty(res) && !_.isEmpty(res.tagsList)) {
        setTagsList(res.tagsList);
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const onTagHiddenToggle = () => {
    if (!currentEditVisItem) return;
    const tagHidden = _.find([...tagsList], {'tag': currentEditVisItem.tag});
    tagHidden.hidden = !tagHidden.hidden;

    updateTagging(tagHidden).then(res => {
      if (res?.status === 500) {
        console.log('hide tag failed, res=', res);
      } else {
        //refresh data
        getTagsList();
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setDisplayVisDialog(false);
    });
  };

  const onTagHiddenDialogToggle = (currentEditItem: IFeedbackTag) => {
    if (!currentEditItem) return;
    setDisplayVisDialog(true);
    setCurrentEditVisItem({
      tag: currentEditItem.tag,
      port: currentEditItem.port.join(','),
      tab: currentEditItem.tab.join(','),
      hidden: currentEditItem.hidden
    } as IFeedbackTagVisible);
  }

  const tagHiddenDialogConfirmText = () => {
    return currentEditVisItem && !currentEditVisItem.hidden ? 'Hide' : 'Show';
  }

  const callToHandleTagModal = (type, tagModal = initTagModal) => {
    switch (type) {
      case OPERATION_TYPE.ADD_NEW:
        setIsAddNewTag(true);
        setTagModal(initTagModal);
        setIsShowModal(true);
        break;
      case OPERATION_TYPE.EDIT:
        let tabValMatched = TAB_OPTIONS_MUTIL_SEL_DD_VALUE_MAPPING.map(option =>
          tagModal.tab.includes(option.text) ? option : {...option, selected: false}
        );
        let tagModalFormat = {
          ...tagModal,
          tab: tabValMatched
        };
        setTagModal(tagModalFormat);
        setIsAddNewTag(false);
        setIsShowModal(true);
        break;
      case OPERATION_TYPE.MODAL_ADD_NEW:
        setIsAddNewTag(false);
        setIsShowModal(false);
        break;
      case OPERATION_TYPE.MODAL_EDIT_CONFIRM:
      default:
        setIsShowModal(false);
        break;
    }
  }

  const checkIfNewTagExists = (newTagItem) => {
    let tagResult = _.find([...tagsList], {'tag': newTagItem.tag});
    return tagResult ? true : false;
  }

  const addNewTag = (newTagItem) => {
    let newTagObj = {
      ...newTagItem,
      tab: newTagItem.tab.map(item => item.text)
    };

    updateTagging(newTagObj).then(res => {
      if (res?.status === 500) {
        console.log('add new tag failed, res=', res);
      } else {
        //refresh data
        getTagsList();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const sortData = (key, data) => {
    let tagsListTemp = data.map(tagItem => {
      return {
        ...tagItem,
        portSort: _.join(tagItem.port, ','),
        tabSort: _.join(tagItem.tab, ','),
      }
    });
    let sortedData = tagsListTemp.sort((a, b) => {
      return a[`${key}Sort`].toString().localeCompare(b[`${key}Sort`].toString(), undefined)
    });
    setTagsList(sortedData);
  }

  const editTagItem = (tagItem) => {
    let tagEditFormat = {...tagItem, tab: tagItem.tab.map(item => item.text)};

    updateTagging(tagEditFormat).then(res => {
      if (res?.status === 500) {
        console.log('edit tag failed, res=', res);
      } else {
        //refresh data
        getTagsList();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const onClickBtn = (type, tagItem) => {
    switch (type) {
      case OPERATION_TYPE.HIDE:
        // if (window.confirm(HIDE_TAG_CONFIRM_MSG))
        onTagHiddenDialogToggle(tagItem)
        break;
      case OPERATION_TYPE.EDIT:
        //call to open edit tag popup
        callToHandleTagModal(type, tagItem);
        break;
      case OPERATION_TYPE.ADD_NEW:
        callToHandleTagModal(type);
        break;
      case OPERATION_TYPE.MODAL_ADD_NEW:
        if (checkIfNewTagExists(tagItem)) {
          window.alert(TAG_EXISTS_ALERT_MSG)
          return;
        }
        addNewTag(tagItem);
        callToHandleTagModal(type, tagItem);
        break;
      case OPERATION_TYPE.MODAL_CANCEL:
        callToHandleTagModal(type, tagItem);
        break;
      case OPERATION_TYPE.MODAL_EDIT_CONFIRM:
        editTagItem(tagItem);
        callToHandleTagModal(type, tagItem);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (checkPagePermission([isAdmin])) {
      getTagsList();
    } else {
      alert(NO_ACCESS_ALERT_MSG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tagsList && tagsList.length > 0)
      onClickSearch();
  }, [tagsList]);

  const CrewFeedbackTaggingTableProps = {
    fieldNames: TAG_LIST_FIELDS,
    data: tagsListSearch,
    type: GENERIC_TABLE_TYPES.crewFeedbackTagging,
    onClickRowBtn: (type, rowTagItem) => {
      onClickBtn(type, rowTagItem)
    },
    sortData: (key, data) => {
      sortData(key, data)
    }
  }

  const CrewFeedbackTagsModalProps = {
    isAddNew: isAddNewTag,
    isShow: isShowModal,
    tagModal,
    setTagModal,
    onHide: () => {
      onClickBtn(OPERATION_TYPE.MODAL_CANCEL, null)
    },
    onCancelClick: () => {
      onClickBtn(OPERATION_TYPE.MODAL_CANCEL, null)
    },
    onConfirmClick: (type, tagForm) => {
      onClickBtn(type, tagForm);
    },
  }

  const renderTaggingPage = () => {
    return <div className='feedback-tags-container p-2 ps-0'>
      <CRow className='mt-4 mb-4'>
        <CCol sm={1}>
          <CXTextInput placeholder={"Port"} value={tagsSearch.port} onChange={(port: string) => {
            setTagsSearch({...tagsSearch, port})
          }} zIndex={1029}/>
        </CCol>
        <CCol sm={1} className="ps-0">
          <CXDropDown
            value={tagsSearch.tab}
            placeholder={"Tab"}
            onChange={(tab: string) => {
              setTagsSearch({...tagsSearch, tab})
            }}
            options={TAB_OPTIONS}
            zIndex={1000}/>
        </CCol>
        <CCol sm={2}>
          <CXButton text={"Search"} onClick={() => {
            onClickSearch()
          }} fontSize={"16px"} width={"100%"} disabled={false}/>
        </CCol>
        <CCol sm={6}></CCol>
        <CCol sm={2}>
          <CXButton outline={true} text={"Add new tag"} onClick={() => {
            onClickBtn(OPERATION_TYPE.ADD_NEW, null)
          }} disabled={false} fontSize={"16px"} width={"100%"}/>
        </CCol>
      </CRow>

      <CrewFeedbackTaggingTable {...CrewFeedbackTaggingTableProps} />

      {/* Modal Handle - Add new or edit */}
      <CrewFeedbackTagModal {...CrewFeedbackTagsModalProps} />
      {/* --------- Hide/Show Feedback --------- */}
      {displayVisDialog && (
        <TagVisibilityDialog
          title="Hide Tag"
          description="Are you sure to hide this tag selection for crew feedback on Flight Folder app?"
          tableHeaders={hiddenTagHeaders}
          data={currentEditVisItem}
          btnConfirmText={tagHiddenDialogConfirmText()}
          onConfirm={onTagHiddenToggle}
          btnCancelText={'Cancel'}
          onCancel={() => setDisplayVisDialog(false)}
        />
      )}
    </div>
  }

  return checkPagePermission([isAdmin]) ? renderTaggingPage() : null;
}

export default FeedbackTaggingPage;
