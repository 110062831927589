import React, { useEffect, useState, useCallback } from 'react';
import { CRow } from '@coreui/react-pro';
import '@coreui/coreui/dist/css/coreui.min.css'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import PortPageRunwayTabContainer from './PortPageRunwayTabContainer';
import { selectPortPageRunway } from '../../slice/portPageRunwaySlice';
import { loadRunways } from '../../slice/portPageRunwaySlice';
import portRunwayService from '../../services/portPageRunway/portRunwayAPI';

interface IPortPageRunwayProps {
  readOnly: boolean;
  portDetail: {
    airline: string;
    icao: string;
    cityName: string;
    countryName: string;
  };
}

const PortPageRunway = (props: IPortPageRunwayProps) => {
  const dispatch = useAppDispatch();
  const { readOnly, portDetail } = props;
  const { airline, icao } = portDetail;

  const [resultFound, setResultFound] = useState<boolean>(true);
  const { originalRunways, tempRunways } = useAppSelector(selectPortPageRunway);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const calltoGetRunways = useCallback(() => {
    let tempObj = {
      airline,
      icao
    };

    portRunwayService.getRunways(tempObj).then((res) => {
      if (res?.status === 500) {
        setResultFound(false);
      } else if (res && res.runways) {
        dispatch(loadRunways(res.runways));
        setResultFound(true);
      }

    }).catch((err) => {
      console.log(err);
    });
  }, [airline, dispatch, icao]);

  useEffect(() => {
    if (airline && icao)
      calltoGetRunways();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const runwayTabContainerProps = {
    readOnly,
    isEditMode,
    setIsEditMode,
    runways: isEditMode ? tempRunways :
      originalRunways && originalRunways.length > 0 ? originalRunways : tempRunways,
    calltoGetRunways,
    portDetail
  }

  return (
    <>
      {!resultFound &&
        <CRow className={"p-2 ms-auto"}>
          <span className={"container-not-found"}> Runway Not Found</span>
        </CRow>
      }
      {resultFound && <PortPageRunwayTabContainer {...runwayTabContainerProps} />}
    </>
  );
};

export default PortPageRunway;
